import React, { useState } from 'react';
import '../../components/styles.css';

const TemperatureConverter = () => {
  const [temperatureValue, setTemperatureValue] = useState('');
  const [fromUnit, setFromUnit] = useState('°C');
  const [toUnit, setToUnit] = useState('°C');
  const [result, setResult] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const convertTemperature = () => {
    const value = parseFloat(temperatureValue);
    let convertedValue;

    // Chuyển đổi giữa các đơn vị
    if (fromUnit === '°C') {
      if (toUnit === '°F') {
        convertedValue = (value * 9/5) + 32; // °C to °F
      } else if (toUnit === 'K') {
        convertedValue = value + 273.15; // °C to K
      } else if (toUnit === '°R') {
        convertedValue = value * 4/5; // °C to °R
      }
    } else if (fromUnit === '°F') {
      if (toUnit === '°C') {
        convertedValue = (value - 32) * 5/9; // °F to °C
      } else if (toUnit === 'K') {
        convertedValue = (value - 32) * 5/9 + 273.15; // °F to K
      } else if (toUnit === '°R') {
        convertedValue = (value + 459.67) * 5/9; // °F to °R
      }
    } else if (fromUnit === 'K') {
      if (toUnit === '°C') {
        convertedValue = value - 273.15; // K to °C
      } else if (toUnit === '°F') {
        convertedValue = (value - 273.15) * 9/5 + 32; // K to °F
      } else if (toUnit === '°R') {
        convertedValue = value * 9/5; // K to °R
      }
    } else if (fromUnit === '°R') {
      if (toUnit === '°C') {
        convertedValue = value * 5/4; // °R to °C
      } else if (toUnit === '°F') {
        convertedValue = (value * 9/4) - 459.67; // °R to °F
      } else if (toUnit === 'K') {
        convertedValue = value * 5/9; // °R to K
      }
    }

    if (convertedValue !== undefined) {
      setResult(convertedValue.toFixed(decimalPlaces));
    }
  };

  const clearFields = () => {
    setTemperatureValue('');
    setFromUnit('°C');
    setToUnit('°C');
    setResult(null);
    setDecimalPlaces(2);
  };

  return (
    <div className="container">
      <h2>Máy tính chuyển đổi nhiệt độ</h2>
      <p>Hãy đưa ra nhiệt độ và chọn đơn vị</p>
      <input
        type="number"
        value={temperatureValue}
        onChange={(e) => setTemperatureValue(e.target.value)}
        placeholder="Nhập giá trị"
      />
      <select value={fromUnit} onChange={(e) => setFromUnit(e.target.value)}>
        <option value="°C">độ Celsius (°C)</option>
        <option value="°F">độ Fahrenheit (°F)</option>
        <option value="K">kelvin (K)</option>
        <option value="°R">độ Réaumur (°R)</option>
      </select>
      <span>đến</span>
      <select value={toUnit} onChange={(e) => setToUnit(e.target.value)}>
        <option value="°C">độ Celsius (°C)</option>
        <option value="°F">độ Fahrenheit (°F)</option>
        <option value="K">kelvin (K)</option>
        <option value="°R">độ Réaumur (°R)</option>
      </select>

      <button onClick={convertTemperature}>Chuyển đổi</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Kết quả: {result}</p>
        </div>
      )}

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
          min="0"
        />
        <span>số thập phân</span>
      </div>
    </div>
  );
};

export default TemperatureConverter;
