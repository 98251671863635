import React from 'react';
import '../Pages/styles/styles.css';

const EnergyAndFuel = () => {
  const energyAndFuelItems = [
    {
      title: 'Tiêu thụ gas',
      image: 'path-to-image-gas-consumption.jpg',
      link: '/energy/gas-consumption'
    },
    {
      title: 'Tiêu thụ nhiên liệu',
      image: 'path-to-image-fuel-consumption.jpg',
      link: '/energy/fuel-consumption'
    },
    {
      title: 'Tiêu thụ điện',
      image: 'path-to-image-electricity-consumption.jpg',
      link: '/energy/electricity-consumption'
    }
  ];

  return (
    <div className='content'>
        <h1>Năng lượng và Nhiên liệu</h1>
        <div className="geometry-container"> {/* Sử dụng chung CSS với geometry.js */}
            {energyAndFuelItems.map((item, index) => (
                <div key={index} className="geometry-item"> {/* Sử dụng chung CSS */}
                <h2>{item.title}</h2>
                <img src={item.image} alt={item.title} />
                <a href={item.link}>
                    <i className="arrow-icon">➡️</i>
                </a>
                </div>
            ))}
        </div>
    </div>
  );
};

export default EnergyAndFuel;
