// Cylinder.js
import React, { useState } from 'react';
import '../../components/styles.css';

const Cylinder = () => {
  const [radius, setRadius] = useState('');
  const [height, setHeight] = useState('');
  const [volume, setVolume] = useState(null);
  const [surfaceArea, setSurfaceArea] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const calculateCylinder = () => {
    const r = parseFloat(radius);
    const h = parseFloat(height);
    if (r > 0 && h > 0) {
      const volumeValue = (Math.PI * Math.pow(r, 2) * h).toFixed(decimalPlaces);
      const surfaceAreaValue = (2 * Math.PI * r * (r + h)).toFixed(decimalPlaces);
      setVolume(volumeValue);
      setSurfaceArea(surfaceAreaValue);
    } else {
      setVolume('Giá trị không hợp lệ');
      setSurfaceArea('Giá trị không hợp lệ');
    }
  };

  const clearFields = () => {
    setRadius('');
    setHeight('');
    setVolume(null);
    setSurfaceArea(null);
  };

  return (
    <div className='content'>
    <h1>Thể Tích và Diện Tích Hình Trụ Tròn</h1>
    <p>Thể tích được tính bằng diện tích đáy nhân với chiều cao. Nếu một hình trụ tròn có bán kính đáy là <strong>r</strong> và chiều cao <strong>h</strong>, thì thể tích được tính bằng:</p>
    <p><strong>V = π.r<sup>2</sup>.h</strong></p>

    <h2>Diện Tích</h2>
    <p>Diện tích toàn phần là: <strong>A = 2.π.r.(r + h)</strong></p>
    <p>Diện tích xung quanh: <strong>A = 2.π.r.h</strong></p>

    <h2>Khái Niệm Hình Trụ Tròn</h2>
    <p>Hình trụ tròn là hình khối được tạo bởi hai mặt đáy song song và vỏ mặt bên. Vỏ mặt bên vuông góc với đáy và tạo thành vòng tròn.</p>

    <h2>Các Công Thức</h2>
    <img src="/image/TheTich-DienTich/the-tich-va-dien-tich-hinh-cau.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/TheTich-DienTich/cong-thuc-the-tich-va-dien-tich-hinh-cau-224x300.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
    <ul>
        <li><strong>V</strong> – thể tích</li>
        <li><strong>A</strong> – diện tích</li>
        <li><strong>A<sub>d</sub></strong> – diện tích đáy</li>
        <li><strong>A<sub>xq</sub></strong> – diện tích xung quanh</li>
        <li><strong>r</strong> – bán kính</li>
        <li><strong>d</strong> – đường kính</li>
        <li><strong>h</strong> – chiều cao</li>
        <li><strong>S'</strong>, <strong>S''</strong> – tâm đáy</li>
    </ul>

    <div className="container">
      <h2>Máy tính thể tích và diện tích hình trụ tròn Online</h2>
      <div className="input-container">
        <span>Bán kính =</span>
        <input type="number" value={radius} onChange={(e) => setRadius(e.target.value)} />
      </div>
      <div className="input-container">
        <span>Chiều cao =</span>
        <input type="number" value={height} onChange={(e) => setHeight(e.target.value)} />
      </div>
      <div className="decimal-container">
        <label>Làm tròn</label>
        <input type="number" value={decimalPlaces} onChange={(e) => setDecimalPlaces(e.target.value)} />
        <span>số thập phân</span>
      </div>
      <button onClick={calculateCylinder}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>
      {volume !== null && (
        <div className="result">
          <p>Thể tích V = {volume}</p>
          <p>Diện tích A = {surfaceArea}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default Cylinder;
