import React from 'react';
import '../Pages/styles/styles.css';

const Exponentiation = () => {
  const items = [
    {
      title: 'Khai căn bậc n',
      image: 'path-to-image-root-n.jpg',
      link: '/exponentiation/nth-root'
    },
    {
      title: 'Khai căn bậc 3',
      image: 'path-to-image-root-3.jpg',
      link: '/exponentiation/cubic-root'
    },
    {
      title: 'Khai căn bậc 2',
      image: 'path-to-image-root-2.jpg',
      link: '/exponentiation/square-root'
    },
    {
      title: 'Lũy thừa bậc n',
      image: 'path-to-image-power-n.jpg',
      link: '/exponentiation/nth-exponent'
    },
    {
      title: 'Lũy thừa bậc 3',
      image: 'path-to-image-power-3.jpg',
      link: '/exponentiation/cubic-exponent'
    },
    {
      title: 'Lũy thừa bậc 2',
      image: 'path-to-image-power-2.jpg',
      link: '/exponentiation/square-exponent'
    }
  ];

  return (
    <div className='content'>
        <h1>Lũy thừa và Khai căn</h1>
        <div className="geometry-container"> 
            {items.map((item, index) => (
                <div key={index} className="geometry-item"> 
                <h2>{item.title}</h2>
                <img src={item.image} alt={item.title} />
                <a href={item.link}>
                    <i className="arrow-icon">➡️</i>
                </a>
                </div>
            ))}
        </div>
    </div>
  );
};

export default Exponentiation;
