import React, { useState } from 'react';
import '../../components/styles.css';

const SpeedConverter = () => {
  const [speedValue, setSpeedValue] = useState('');
  const [fromUnit, setFromUnit] = useState('m/s');
  const [toUnit, setToUnit] = useState('m/s');
  const [result, setResult] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const convertSpeed = () => {
    const value = parseFloat(speedValue);
    let convertedValue;

    // Chuyển đổi giữa các đơn vị
    const conversionRates = {
      'm/s': {
        'm/s': 1,
        'km/h': 3.6,
        'mph': 2.236936,
        'kn': 1.943844,
        'fps': 3.28084
      },
      'km/h': {
        'm/s': 0.277778,
        'km/h': 1,
        'mph': 0.621371,
        'kn': 0.539957,
        'fps': 0.911344
      },
      'mph': {
        'm/s': 0.44704,
        'km/h': 1.609344,
        'mph': 1,
        'kn': 0.868976,
        'fps': 1.466667
      },
      'kn': {
        'm/s': 0.514444,
        'km/h': 1.852,
        'mph': 1.150779,
        'kn': 1,
        'fps': 1.68781
      },
      'fps': {
        'm/s': 0.3048,
        'km/h': 1.09728,
        'mph': 0.681818,
        'kn': 0.592484,
        'fps': 1
      }
    };

    if (fromUnit && toUnit) {
      convertedValue = value * conversionRates[fromUnit][toUnit];
      setResult(convertedValue.toFixed(decimalPlaces));
    }
  };

  const clearFields = () => {
    setSpeedValue('');
    setFromUnit('m/s');
    setToUnit('m/s');
    setResult(null);
    setDecimalPlaces(2);
  };

  return (
    <div className="container">
      <h2>Máy tính chuyển đổi vận tốc</h2>
      <p>Hãy đưa ra vận tốc và chọn đơn vị</p>
      <input
        type="number"
        value={speedValue}
        onChange={(e) => setSpeedValue(e.target.value)}
        placeholder="Nhập giá trị"
      />
      <select value={fromUnit} onChange={(e) => setFromUnit(e.target.value)}>
        <option value="m/s">mét trên giây (m/s)</option>
        <option value="km/h">kilômet trên giờ (km/h)</option>
        <option value="mph">dặm trên giờ (mph)</option>
        <option value="kn">nút (kn)</option>
        <option value="fps">bộ trên giây (fps)</option>
      </select>
      <span>đến</span>
      <select value={toUnit} onChange={(e) => setToUnit(e.target.value)}>
        <option value="m/s">mét trên giây (m/s)</option>
        <option value="km/h">kilômet trên giờ (km/h)</option>
        <option value="mph">dặm trên giờ (mph)</option>
        <option value="kn">nút (kn)</option>
        <option value="fps">bộ trên giây (fps)</option>
      </select>

      <button onClick={convertSpeed}>Chuyển đổi</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Kết quả: {result}</p>
        </div>
      )}

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
          min="0"
        />
        <span>số thập phân</span>
      </div>
    </div>
  );
};

export default SpeedConverter;
