import React, { useState } from 'react';
import '../../components/styles.css'; 

const Trapezoid = () => {
  const [a, setA] = useState('');
  const [c, setC] = useState('');
  const [h, setH] = useState('');
  const [perimeter, setPerimeter] = useState(null);
  const [area, setArea] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  // Hàm tính chu vi và diện tích hình thang
  const calculateTrapezoid = () => {
    const baseA = parseFloat(a);
    const baseC = parseFloat(c);
    const height = parseFloat(h);

    // Tính diện tích
    const S = ((baseA + baseC) / 2) * height; // Diện tích

    // Tính chu vi, giả sử cạnh bên b và d có cùng độ dài là x
    const b = 0; // Giả sử giá trị cạnh bên b là x
    const d = 0; // Giả sử giá trị cạnh bên d là x
    const P = baseA + baseC + b + d; // Chu vi

    setArea(S.toFixed(decimalPlaces));
    setPerimeter(P.toFixed(decimalPlaces));
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setA('');
    setC('');
    setH('');
    setPerimeter(null);
    setArea(null);
  };

  return (
    <div class="content">
        <h1>Diện Tích và Chu Vi Hình Thang</h1>
        
        <h2>Cách Tính Chu Vi</h2>
        <p>Chu vi hình thang là tổng độ dài của tất cả các cạnh. Hình thang chỉ có một cặp cạnh song song.</p>
        

        <h2>Cách Tính Diện Tích</h2>
        <p>Diện tích hình thang được tính bằng trung bình cộng của độ dài hai cạnh đáy nhân với chiều cao.</p>
        
        <h2>Các Công Thức</h2>
        <img src="/image/DienTichChuVi/dien-tich-hinh-thang-300x240.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/DienTichChuVi/cong-thuc-dien-tich-hinh-thang-300x283.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />

        <h2>Định Nghĩa Hình Thang</h2>
        <p>
            <ul>
                <li>Hình thang là hình bình hành chỉ có một cặp cạnh song song.</li>
                <li>Các cạnh song song được gọi là cạnh đáy, các cạnh còn lại là cạnh bên.</li>
                <li>Các đường chéo không cắt nhau tại điểm giữa cũng không vuông góc với nhau.</li>
                <li>Đường cao là khoảng cách vuông góc giữa các cạnh đáy.</li>
            </ul>
        </p>

        <h2>Các Thành Phần</h2>
        <p>
            <ul>
                <li><strong>P</strong> – chu vi</li>
                <li><strong>S</strong> – diện tích</li>
                <li><strong>a, c</strong> – cạnh đáy</li>
                <li><strong>b, d</strong> – cạnh bên</li>
                <li><strong>h</strong> – chiều cao</li>
            </ul>
        </p>

        <p class="note">Lưu ý: Để tính toán chính xác, bạn cần biết độ dài các cạnh và chiều cao của hình thang.</p>


    <div className="container">
      <h2>Máy tính diện tích hình thang Online</h2>
      <p>Hãy đưa ra 3 giá trị</p>

      <div className="input-container">
        <span>a =</span>
        <input
          type="number"
          value={a}
          onChange={(e) => setA(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>c =</span>
        <input
          type="number"
          value={c}
          onChange={(e) => setC(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>h =</span>
        <input
          type="number"
          value={h}
          onChange={(e) => setH(e.target.value)}
        />
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateTrapezoid}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {perimeter !== null && area !== null && (
        <div className="result">
          <p>Chu vi P = {perimeter}</p>
          <p>Diện tích S = {area}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default Trapezoid;
