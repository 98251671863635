import React, { useState } from 'react';
import '../../components/styles.css'; 

const CosCalculator = () => {
  const [angle, setAngle] = useState('');
  const [cosValue, setCosValue] = useState(null);
  const [roundedCos, setRoundedCos] = useState(6);
  const [alpha1Value, setAlpha1Value] = useState('');
  const [alpha2Value, setAlpha2Value] = useState('');
  const [angleUnit, setAngleUnit] = useState('deg'); // 'deg' cho độ, 'rad' cho radian

  // Hàm tính giá trị cos
  const calculateCos = () => {
    let radians = angleUnit === 'deg' ? (angle * Math.PI) / 180 : angle;
    const cosResult = Math.cos(radians);
    setCosValue(cosResult.toFixed(roundedCos));
  };

  // Hàm tính giá trị ngược lại (cos^-1)
  const calculateInverseCos = () => {
    const value = parseFloat(alpha1Value);
    if (value < -1 || value > 1) {
      setAlpha2Value('Giá trị không hợp lệ');
    } else {
      const angleInRad = Math.acos(value); // Tính arccos
      const angleInDeg = (angleInRad * 180) / Math.PI; // Chuyển đổi sang độ
      const kValue1 = ` + k.360°`; // Để tính thêm các nghiệm
      const kValue2 = ` + k.360°`;
      setAlpha2Value(`${angleInDeg.toFixed(4)}°${kValue1} và ${(-angleInDeg).toFixed(4)}°${kValue2}`);
    }
  };

  return (
    <div className="container">
      <h2>Máy Tính Giải Cos Online</h2>

      <h3>Hãy viết góc α</h3>
      <div className="input-container">
        <span>α =</span>
        <input
          type="number"
          value={angle}
          onChange={(e) => setAngle(e.target.value)}
        />
        <select value={angleUnit} onChange={(e) => setAngleUnit(e.target.value)}>
          <option value="deg">°</option>
          <option value="rad">rad</option>
        </select>
      </div>
      
      <div className="rounded-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={roundedCos}
          onChange={(e) => setRoundedCos(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateCos}>Tính cos α</button>
      
      {cosValue !== null && (
        <div className="result">
          <p>cos α = {cosValue}</p>
        </div>
      )}

      <h3>Hãy cho giá trị cos α và chọn đơn vị góc</h3>
      <div className="input-container">
        <span>cos α =</span>
        <input
          type="number"
          value={alpha1Value}
          onChange={(e) => setAlpha1Value(e.target.value)}
        />
      </div>
      <button onClick={calculateInverseCos}>Tính α từ cos α</button>
      
      {alpha2Value && (
        <div className="result">
          <p>α1 = {alpha2Value}</p>
        </div>
      )}
    </div>
  );
};

export default CosCalculator;
