import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
  const [openCategory, setOpenCategory] = useState(null);

  const toggleCategory = (category) => {
    if (openCategory === category) {
      setOpenCategory(null);
    } else {
      setOpenCategory(category);
    }
  };

  return (
    <div className="sidebar">

      {/* Máy Tính*/}
      <div className="category">
        <NavLink to="/calculator" activeClassName="active-link" onClick={() => setOpenCategory(null)}>Máy Tính</NavLink>
      </div>

      {/* Tính phần trăm */}
      <div className="category">
        <NavLink to="/percentage" activeClassName="active-link" onClick={() => setOpenCategory(null)}>Tính Phần trăm</NavLink>
      </div>

      {/* Danh mục Hình học */}
      <div className="category">
        <NavLink to="/geometry" activeClassName="active-link" onClick={() => toggleCategory('geometry')}>
          Diện Tích và Chu Vi {openCategory === 'geometry' ? '[-]' : '[+]'}
        </NavLink>
        {openCategory === 'geometry' && (
          <ul className="sub-menu">
            <li><NavLink to="/geometry/circle" activeClassName="active-link">Hình tròn</NavLink></li>
            <li><NavLink to="/geometry/triangle" activeClassName="active-link">Hình tam giác</NavLink></li>
            <li><NavLink to="/geometry/righttriangle" activeClassName="active-link">Tam giác vuông</NavLink></li>
            <li><NavLink to="/geometry/square" activeClassName="active-link">Hình vuông</NavLink></li>
            <li><NavLink to="/geometry/rectangle" activeClassName="active-link">Hình chữ nhật</NavLink></li>
            <li><NavLink to="/geometry/rhombus" activeClassName="active-link">Hình thoi</NavLink></li>
            <li><NavLink to="/geometry/parallelogram" activeClassName="active-link">Hình bình hành</NavLink></li>
            <li><NavLink to="/geometry/trapezoid" activeClassName="active-link">Hình thang</NavLink></li>
            <li><NavLink to="/geometry/pentagon" activeClassName="active-link">Hình ngũ giác</NavLink></li>
            <li><NavLink to="/geometry/hexagon" activeClassName="active-link">Hình lục giác</NavLink></li>
            <li><NavLink to="/geometry/polygon" activeClassName="active-link">Đa giác</NavLink></li>
            <li><NavLink to="/geometry/pythagoreantheorem" activeClassName="active-link">Định lý Pythagore</NavLink></li>
          </ul>
        )}
      </div>

      {/* Các danh mục khác */}
      <div className="category">
        <NavLink to="/exponentiation" activeClassName="active-link" onClick={() => toggleCategory('exponentiation')}>
          Lũy Thừa và Khai Căn {openCategory === 'exponentiation' ? '[-]' : '[+]'}
        </NavLink>
        {openCategory === 'exponentiation' && (
          <ul className="sub-menu">
            <li><NavLink to="/exponentiation/square-root" activeClassName="active-link">Khai căn bậc 2</NavLink></li>
            <li><NavLink to="/exponentiation/cubic-root" activeClassName="active-link">Khai căn bậc 3</NavLink></li>
            <li><NavLink to="/exponentiation/nth-root" activeClassName="active-link">Khai căn bậc n</NavLink></li>
            <li><NavLink to="/exponentiation/square-exponent" activeClassName="active-link">Lũy thừa bậc 2</NavLink></li>
            <li><NavLink to="/exponentiation/cubic-exponent" activeClassName="active-link">Lũy thừa bậc 3</NavLink></li>
            <li><NavLink to="/exponentiation/nth-exponent" activeClassName="active-link">Lũy thừa bậc n</NavLink></li>
          </ul>
        )}
      </div>

      {/* Thêm các danh mục khác */}
      <div className="category">
        <NavLink to="/equations" activeClassName="active-link" onClick={() => toggleCategory('equations')}>
          Phương Trình {openCategory === 'equations' ? '[-]' : '[+]'}
        </NavLink>
        {openCategory === 'equations' && (
          <ul className="sub-menu">
            <li><NavLink to="/equations/linear" activeClassName="active-link">Phương trình bậc nhất</NavLink></li>
            <li><NavLink to="/equations/quadratic" activeClassName="active-link">Phương trình bậc hai</NavLink></li>
            <li><NavLink to="/equations/system" activeClassName="active-link">Hệ phương trình</NavLink></li>
          </ul>
        )}
      </div>

            {/* Danh mục Trung Bình */}
            <div className="category">
        <NavLink to="/average" activeClassName="active-link" onClick={() => toggleCategory('average')}>
          Trung Bình {openCategory === 'average' ? '[-]' : '[+]'}
        </NavLink>
        {openCategory === 'average' && (
          <ul className="sub-menu">
            <li><NavLink to="/average/simple-mean" activeClassName="active-link">Trung bình cộng đơn giản</NavLink></li>
            <li><NavLink to="/average/weighted-mean" activeClassName="active-link">Trung bình cộng có trọng số</NavLink></li>
          </ul>
        )}
      </div>

      {/* Danh mục Thể Tích và Diện Tích */}
      <div className="category">
        <NavLink to="/volume-area" activeClassName="active-link" onClick={() => toggleCategory('volumeArea')}>
          Thể Tích và Diện Tích {openCategory === 'volumeArea' ? '[-]' : '[+]'}
        </NavLink>
        {openCategory === 'volumeArea' && (
          <ul className="sub-menu">
            <li><NavLink to="/volume-area/sphere" activeClassName="active-link">Hình cầu</NavLink></li>
            <li><NavLink to="/volume-area/pyramid" activeClassName="active-link">Hình chóp</NavLink></li>
            <li><NavLink to="/volume-area/rectangular-box" activeClassName="active-link">Hình hộp chữ nhật</NavLink></li>
            <li><NavLink to="/volume-area/prism" activeClassName="active-link">Hình lăng trụ</NavLink></li>
            <li><NavLink to="/volume-area/cone" activeClassName="active-link">Hình nón</NavLink></li>
            <li><NavLink to="/volume-area/cylinder" activeClassName="active-link">Hình trụ tròn</NavLink></li>
            <li><NavLink to="/volume-area/cube" activeClassName="active-link">Hình lập phương</NavLink></li>
          </ul>
        )}
      </div>

      {/* Danh mục Hàm Lượng Giác */}
      <div className="category">
        <NavLink to="/trigonometric" activeClassName="active-link" onClick={() => toggleCategory('trigonometricFunctions')}>
          Hàm Lượng Giác {openCategory === 'trigonometricFunctions' ? '[-]' : '[+]'}
        </NavLink>
        {openCategory === 'trigonometricFunctions' && (
          <ul className="sub-menu">
            <li><NavLink to="/trigonometric/sin" activeClassName="active-link">Sin</NavLink></li>
            <li><NavLink to="/trigonometric/cos" activeClassName="active-link">Cos</NavLink></li>
            <li><NavLink to="/trigonometric/tan" activeClassName="active-link">Tang</NavLink></li>
            <li><NavLink to="/trigonometric/cot" activeClassName="active-link">Cotang</NavLink></li>
          </ul>
        )}
      </div>

      {/* Danh mục Logarit */}
      <div className="category">
        <NavLink to="/logarithm" activeClassName="active-link" onClick={() => toggleCategory('logarithm')}>
          Logarit {openCategory === 'logarithm' ? '[-]' : '[+]'}
        </NavLink>
        {openCategory === 'logarithm' && (
          <ul className="sub-menu">
            <li><NavLink to="/logarithm/logarithm" activeClassName="active-link">Logarit</NavLink></li>
            <li><NavLink to="/logarithm/natural-log" activeClassName="active-link">Logarit tự nhiên</NavLink></li>
            <li><NavLink to="/logarithm/decimal-log" activeClassName="active-link">Logarit thập phân</NavLink></li>
          </ul>
        )}
      </div>

      {/* Danh mục Năng Lượng và Nhiên Liệu */}
      <div className="category">
        <NavLink to="/energy-fuel" activeClassName="active-link" onClick={() => toggleCategory('energy-fuel')}>
          Năng Lượng và Nhiên Liệu {openCategory === 'energy-fuel' ? '[-]' : '[+]'}
        </NavLink>
        {openCategory === 'energy-fuel' && (
          <ul className="sub-menu">
            <li><NavLink to="/energy-fuel/electricity-consumption" activeClassName="active-link">Tiêu thụ điện</NavLink></li>
            <li><NavLink to="/energy-fuel/fuel-consumption" activeClassName="active-link">Tiêu thụ nhiên liệu</NavLink></li>
            <li><NavLink to="/energy-fuel/gas-consumption" activeClassName="active-link">Tiêu thụ gas</NavLink></li>
          </ul>
        )}
      </div>

      {/* Danh mục Chuyển Đổi Đơn Vị Đo */}
      <div className="category">
        <NavLink to="/unit-conversion" activeClassName="active-link" onClick={() => toggleCategory('unit-conversion')}>
          Chuyển Đổi Đơn Vị Đo {openCategory === 'unit-conversion' ? '[-]' : '[+]'}
        </NavLink>
        {openCategory === 'unit-conversion' && (
          <ul className="sub-menu">
            <li><NavLink to="/unit-conversion/length" activeClassName="active-link">Chiều dài</NavLink></li>
            <li><NavLink to="/unit-conversion/area" activeClassName="active-link">Diện tích</NavLink></li>
            <li><NavLink to="/unit-conversion/volume" activeClassName="active-link">Thể tích</NavLink></li>
            <li><NavLink to="/unit-conversion/mass" activeClassName="active-link">Khối lượng</NavLink></li>
            <li><NavLink to="/unit-conversion/speed" activeClassName="active-link">Vận tốc</NavLink></li>
            <li><NavLink to="/unit-conversion/temperature" activeClassName="active-link">Nhiệt độ</NavLink></li>
            <li><NavLink to="/unit-conversion/angle" activeClassName="active-link">Góc</NavLink></li>
            <li><NavLink to="/unit-conversion/power" activeClassName="active-link">Công suất</NavLink></li>
            <li><NavLink to="/unit-conversion/pressure" activeClassName="active-link">Áp suất</NavLink></li>
            <li><NavLink to="/unit-conversion/energy" activeClassName="active-link">Năng lượng</NavLink></li>
            <li><NavLink to="/unit-conversion/time" activeClassName="active-link">Thời gian</NavLink></li>
          </ul>
        )}
      </div>

    </div>
  );
};

export default Sidebar;
