import React, { useState } from 'react';
import '../../components/styles.css';

const MassConverter = () => {
  const [massValue, setMassValue] = useState('');
  const [fromUnit, setFromUnit] = useState('kg');
  const [toUnit, setToUnit] = useState('kg');
  const [result, setResult] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const convertMass = () => {
    const value = parseFloat(massValue);
    let convertedValue;

    // Chuyển đổi giữa các đơn vị
    const conversionRates = {
      'μg': 1e-9,
      'mg': 1e-6,
      'g': 0.001,
      'dag': 0.01,
      'kg': 1,
      'q': 0.1,
      't': 1000,
      'kt': 1e6,
      'Mt': 1e9,
      'gr': 0.0000647989,
      'dr': 0.00177185,
      'oz': 0.0283495,
      'lb': 0.453592,
      'qu': 0.0254,
      'cwt': 0.0508023,
      'to': 907.185,
      'st': 0.157473,
      'dwt': 0.00155517384,
      'tr oz': 0.0311035,
      'tr lb': 0.453592
    };

    if (fromUnit && toUnit) {
      convertedValue = (value * conversionRates[fromUnit]) / conversionRates[toUnit];
      setResult(convertedValue.toFixed(decimalPlaces));
    }
  };

  const clearFields = () => {
    setMassValue('');
    setFromUnit('kg');
    setToUnit('kg');
    setResult(null);
    setDecimalPlaces(2);
  };

  return (
    <div className="container">
      <h2>Máy tính chuyển đổi khối lượng</h2>
      <p>Hãy đưa ra khối lượng và chọn đơn vị</p>
      <input
        type="number"
        value={massValue}
        onChange={(e) => setMassValue(e.target.value)}
        placeholder="Nhập giá trị"
      />
      <select value={fromUnit} onChange={(e) => setFromUnit(e.target.value)}>
        <option value="μg">micrôgam</option>
        <option value="mg">miligam</option>
        <option value="g">gam</option>
        <option value="dag">đêcagam</option>
        <option value="kg">kilôgam</option>
        <option value="q">tạ</option>
        <option value="t">tấn</option>
        <option value="kt">kilôtấn</option>
        <option value="Mt">mêgatấn</option>
        <option value="gr">grain</option>
        <option value="dr">dram</option>
        <option value="oz">ounce</option>
        <option value="lb">pound</option>
        <option value="qu">quarter</option>
        <option value="cwt">hundredweight</option>
        <option value="to">tấn (short ton)</option>
        <option value="st">stone</option>
        <option value="dwt">pennyweight</option>
        <option value="tr oz">ounce troy</option>
        <option value="tr lb">pound troy</option>
      </select>
      <span>đến</span>
      <select value={toUnit} onChange={(e) => setToUnit(e.target.value)}>
        <option value="μg">micrôgam</option>
        <option value="mg">miligam</option>
        <option value="g">gam</option>
        <option value="dag">đêcagam</option>
        <option value="kg">kilôgam</option>
        <option value="q">tạ</option>
        <option value="t">tấn</option>
        <option value="kt">kilôtấn</option>
        <option value="Mt">mêgatấn</option>
        <option value="gr">grain</option>
        <option value="dr">dram</option>
        <option value="oz">ounce</option>
        <option value="lb">pound</option>
        <option value="qu">quarter</option>
        <option value="cwt">hundredweight</option>
        <option value="to">tấn (short ton)</option>
        <option value="st">stone</option>
        <option value="dwt">pennyweight</option>
        <option value="tr oz">ounce troy</option>
        <option value="tr lb">pound troy</option>
      </select>

      <button onClick={convertMass}>Chuyển đổi</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Kết quả: {result}</p>
        </div>
      )}

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
          min="0"
        />
        <span>số thập phân</span>
      </div>
    </div>
  );
};

export default MassConverter;
