import React from 'react';
import '../Pages/styles/styles.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>Về Chúng Tôi</h1>
      <p>
        Chào mừng bạn đến với trang web của chúng tôi! Chúng tôi là một nền tảng trực tuyến cung cấp các công cụ tính toán miễn phí, tiện lợi và dễ sử dụng. Sứ mệnh của chúng tôi là giúp người dùng có thể dễ dàng thực hiện các phép tính từ đơn giản đến phức tạp, bao gồm các công cụ về toán học, chuyển đổi đơn vị, tính năng lượng và nhiên liệu, cùng nhiều chức năng hữu ích khác.
      </p>

      <h2>Mục Tiêu Của Chúng Tôi</h2>
      <p>
        Mục tiêu của chúng tôi là tạo ra một không gian mà mọi người có thể sử dụng để giải quyết các vấn đề toán học và tính toán hàng ngày một cách dễ dàng. Chúng tôi cam kết phát triển liên tục và không ngừng cập nhật các tính năng mới để phục vụ nhu cầu của bạn.
      </p>

      <h2>Tại Sao Chọn Chúng Tôi?</h2>
      <ul>
        <li>Miễn phí hoàn toàn</li>
        <li>Giao diện đơn giản, dễ sử dụng</li>
        <li>Cung cấp nhiều công cụ hữu ích: từ máy tính toán học đến chuyển đổi đơn vị</li>
        <li>Liên tục cập nhật và phát triển tính năng mới</li>
      </ul>

      <h2>Liên Hệ Với Chúng Tôi</h2>
      <p>
        Nếu bạn có bất kỳ câu hỏi hay phản hồi nào, vui lòng liên hệ với chúng tôi thông qua trang liên hệ hoặc email trực tiếp tới <strong>nam102154@donga.edu.vn</strong>.
      </p>

      <p>
        Cảm ơn bạn đã sử dụng trang web của chúng tôi. Chúng tôi hy vọng rằng các công cụ của chúng tôi sẽ giúp bạn trong công việc và học tập!
      </p>
    </div>
  );
};

export default About;
