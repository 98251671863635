import React from 'react';
import '../Pages/styles/styles.css';

const UnitConversion = () => {
  const unitConversionItems = [
    {
      title: 'Thời gian',
      image: 'path-to-image-time.jpg',
      link: '/unit-conversion/time'
    },
    {
      title: 'Năng lượng',
      image: 'path-to-image-energy.jpg',
      link: '/unit-conversion/energy'
    },
    {
      title: 'Công suất',
      image: 'path-to-image-power.jpg',
      link: '/unit-conversion/power'
    },
    {
      title: 'Áp suất',
      image: 'path-to-image-pressure.jpg',
      link: '/unit-conversion/pressure'
    },
    {
      title: 'Góc',
      image: 'path-to-image-angle.jpg',
      link: '/unit-conversion/angle'
    },
    {
      title: 'Nhiệt độ',
      image: 'path-to-image-temperature.jpg',
      link: '/unit-conversion/temperature'
    },
    {
      title: 'Vận tốc',
      image: 'path-to-image-speed.jpg',
      link: '/unit-conversion/speed'
    },
    {
      title: 'Khối lượng',
      image: 'path-to-image-mass.jpg',
      link: '/unit-conversion/mass'
    },
    {
      title: 'Thể tích',
      image: 'path-to-image-volume.jpg',
      link: '/unit-conversion/volume'
    },
    {
      title: 'Diện tích',
      image: 'path-to-image-area.jpg',
      link: '/unit-conversion/area'
    },
    {
      title: 'Chiều dài',
      image: 'path-to-image-length.jpg',
      link: '/unit-conversion/length'
    }
  ];

  return (
    <div className='content'>
        <h1>Chuyển đổi đơn vị đo</h1>
        <div className="geometry-container"> 
            {unitConversionItems.map((item, index) => (
                <div key={index} className="geometry-item"> 
                <h2>{item.title}</h2>
                <img src={item.image} alt={item.title} />
                <a href={item.link}>
                    <i className="arrow-icon">➡️</i>
                </a>
                </div>
            ))}
        </div>
    </div>
  );
};

export default UnitConversion;
