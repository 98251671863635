import React from 'react';
import '../Pages/styles/styles.css';

const Menu = () => {
  const menuItems = [
    {
      title: 'Máy Tính Online',
      link: '/calculator'
    },
    {
      title: 'Tương quan tỉ lệ',
      link: '/menu/ratio'
    },
    {
      title: 'Tính phần trăm',
      link: '/menu/percent'
    },
    {
      title: 'Chuyển đổi đơn vị đo',
      link: '/menu/conversion'
    },
    {
      title: 'Diện tích và chu vi',
      link: '/menu/area-perimeter'
    },
    {
      title: 'Hàm lượng giác',
      link: '/menu/trigonometry'
    },
    {
      title: 'Logarit',
      link: '/menu/logarithm'
    },
    {
      title: 'Lũy thừa và khai căn',
      link: '/menu/exponentiation'
    },
    {
      title: 'Năng lượng và nhiên liệu',
      link: '/menu/energy'
    },
    {
      title: 'Phương trình',
      link: '/menu/equation'
    },
    {
      title: 'Thể tích và diện tích',
      link: '/menu/volume-area'
    },
    {
      title: 'Trung bình',
      link: '/menu/average'
    }
  ];

  return (
    <div className='content'>
      <h1>Menu Tính Toán</h1>
      <div className="geometry-container">
        {menuItems.map((item, index) => (
          <div key={index} className="geometry-item">
            <h2>{item.title}</h2>
            <a href={item.link}>
              <i className="arrow-icon">➡️</i>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
