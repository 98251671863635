// Cone.js
import React, { useState } from 'react';
import '../../components/styles.css';

const Cone = () => {
  const [radius, setRadius] = useState('');
  const [height, setHeight] = useState('');
  const [volume, setVolume] = useState(null);
  const [surfaceArea, setSurfaceArea] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const calculateCone = () => {
    const r = parseFloat(radius);
    const h = parseFloat(height);
    if (r > 0 && h > 0) {
      const volumeValue = ((1 / 3) * Math.PI * Math.pow(r, 2) * h).toFixed(decimalPlaces);
      const surfaceAreaValue = (Math.PI * r * (r + Math.sqrt(Math.pow(h, 2) + Math.pow(r, 2)))).toFixed(decimalPlaces);
      setVolume(volumeValue);
      setSurfaceArea(surfaceAreaValue);
    } else {
      setVolume('Giá trị không hợp lệ');
      setSurfaceArea('Giá trị không hợp lệ');
    }
  };

  const clearFields = () => {
    setRadius('');
    setHeight('');
    setVolume(null);
    setSurfaceArea(null);
  };

  return (
    <div className='content'>
    <h1>Thể Tích và Diện Tích Hình Nón</h1>
    <p>Thể tích hình nón bằng 1/3 diện tích mặt đáy nhân với chiều cao <strong>h</strong>.</p>
    <p>Diện tích xung quanh hình nón bằng <strong>π</strong> nhân bán kính <strong>r</strong> nhân với đường sinh <strong>s</strong>.</p>
    <p>Diện tích toàn phần của hình nón bằng diện tích xung quanh cộng với diện tích mặt đáy.</p>

    <h2>Khái Niệm Hình Nón</h2>
    <p>Hình nón tròn được tạo bởi một vòng tròn đáy và vỏ mặt bên. Chiều cao của hình nón vuông góc với mặt đáy của nó.</p>

    <h2>Các Công Thức</h2>
      <img src="/image/TheTich-DienTich/the-tich-va-dien-tich-hinh-cau.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
      <img src="/image/TheTich-DienTich/cong-thuc-the-tich-va-dien-tich-hinh-cau-224x300.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
    <p>Thể tích và diện tích hình nón được tính theo các công thức sau:</p>
    <ul>
        <li><strong>V</strong> – thể tích</li>
        <li><strong>A</strong> – diện tích</li>
        <li><strong>A<sub>d</sub></strong> – diện tích đáy</li>
        <li><strong>A<sub>xq</sub></strong> – diện tích xung quanh</li>
        <li><strong>r</strong> – bán kính</li>
        <li><strong>d</strong> – đường kính</li>
        <li><strong>h</strong> – chiều cao</li>
        <li><strong>s</strong> – bán kính vỏ mặt bên</li>
        <li><strong>S'</strong> – tâm đáy</li>
        <li><strong>V'</strong> – chóp nón</li>
    </ul>

    <div className="container">
      <h2>Máy tính thể tích và diện tích hình nón Online</h2>
      <div className="input-container">
        <span>Bán kính =</span>
        <input type="number" value={radius} onChange={(e) => setRadius(e.target.value)} />
      </div>
      <div className="input-container">
        <span>Chiều cao =</span>
        <input type="number" value={height} onChange={(e) => setHeight(e.target.value)} />
      </div>
      <div className="decimal-container">
        <label>Làm tròn</label>
        <input type="number" value={decimalPlaces} onChange={(e) => setDecimalPlaces(e.target.value)} />
        <span>số thập phân</span>
      </div>
      <button onClick={calculateCone}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>
      {volume !== null && (
        <div className="result">
          <p>Thể tích V = {volume}</p>
          <p>Diện tích A = {surfaceArea}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default Cone;
