import React, { useState } from 'react';
import '../../components/styles.css';

const EnergyConverter = () => {
  const [energyValue, setEnergyValue] = useState('');
  const [fromUnit, setFromUnit] = useState('J');
  const [toUnit, setToUnit] = useState('J');
  const [result, setResult] = useState(null);

  const convertEnergy = () => {
    const value = parseFloat(energyValue);
    let convertedValue;

    // Chuyển đổi giữa các đơn vị
    const conversionRates = {
      J: 1,
      kJ: 1_000,
      MJ: 1_000_000,
      GJ: 1_000_000_000,
      cal: 4.184,
      kcal: 4_184,
      kWh: 3_600_000,
      MWh: 3_600_000_000,
    };

    // Chuyển đổi từ đơn vị gốc sang joule
    const valueInJ = value * conversionRates[fromUnit];

    // Chuyển đổi từ joule sang đơn vị đích
    convertedValue = valueInJ / conversionRates[toUnit];

    if (convertedValue !== undefined) {
      setResult(convertedValue);
    }
  };

  const clearFields = () => {
    setEnergyValue('');
    setFromUnit('J');
    setToUnit('J');
    setResult(null);
  };

  return (
    <div className="container">
      <h2>Máy tính chuyển đổi năng lượng</h2>
      <p>Hãy đưa ra giá trị năng lượng và chọn đơn vị</p>
      <input
        type="number"
        value={energyValue}
        onChange={(e) => setEnergyValue(e.target.value)}
        placeholder="Nhập giá trị"
      />
      <select value={fromUnit} onChange={(e) => setFromUnit(e.target.value)}>
        <option value="J">jun (J)</option>
        <option value="kJ">kilôjun (kJ)</option>
        <option value="MJ">mêgajun (MJ)</option>
        <option value="GJ">gigajun (GJ)</option>
        <option value="cal">ca-lo (cal)</option>
        <option value="kcal">kilôca-lo (kcal)</option>
        <option value="kWh">kilôwatt giờ (kWh)</option>
        <option value="MWh">mêgawat giờ (MWh)</option>
      </select>
      <span>đến</span>
      <select value={toUnit} onChange={(e) => setToUnit(e.target.value)}>
        <option value="J">jun (J)</option>
        <option value="kJ">kilôjun (kJ)</option>
        <option value="MJ">mêgajun (MJ)</option>
        <option value="GJ">gigajun (GJ)</option>
        <option value="cal">ca-lo (cal)</option>
        <option value="kcal">kilôca-lo (kcal)</option>
        <option value="kWh">kilôwatt giờ (kWh)</option>
        <option value="MWh">mêgawat giờ (MWh)</option>
      </select>

      <button onClick={convertEnergy}>Chuyển đổi</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Kết quả: {result.toFixed(2)} {toUnit}</p>
        </div>
      )}
    </div>
  );
};

export default EnergyConverter;
