import React, { useState } from 'react';
import '../../components/styles.css'; 

const QuadraticEquation = () => {
  const [a, setA] = useState(''); // Hệ số a
  const [b, setB] = useState(''); // Hệ số b
  const [c, setC] = useState(''); // Hệ số c
  const [result, setResult] = useState(null); // Kết quả

  // Hàm tính nghiệm x1 và x2 của phương trình bậc hai
  const calculateSolution = () => {
    const coeffA = parseFloat(a);
    const coeffB = parseFloat(b);
    const coeffC = parseFloat(c);

    if (coeffA === 0) {
      setResult('Hệ số a không được bằng 0');
      return;
    }

    const delta = coeffB ** 2 - 4 * coeffA * coeffC;

    if (delta < 0) {
      setResult('Phương trình vô nghiệm');
    } else {
      const x1 = (-coeffB + Math.sqrt(delta)) / (2 * coeffA);
      const x2 = (-coeffB - Math.sqrt(delta)) / (2 * coeffA);
      setResult(`x1 = ${x1.toFixed(2)}, x2 = ${x2.toFixed(2)}`);
    }
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setA('');
    setB('');
    setC('');
    setResult(null);
  };

  return (
    <div class="content">
    <h1>Phương Trình Bậc Hai</h1>
    
    <h2>Định Nghĩa</h2>
    <p>Phương trình <strong>ax² + bx + c = 0</strong>, với x là ẩn số chưa biết và a, b, c là các số đã biết sao cho a khác 0. Các số a, b, và c là những hệ số của phương trình và có thể phân biệt bằng cách gọi tương ứng hệ số bậc hai, hệ số bậc một, và hằng số hay số hạng tự do.</p>

    <h2>Máy Tính Online Giải Phương Trình Bậc Hai</h2>
    <p>Hãy đưa ra phương trình theo dạng mẫu chung. Trường hợp trong phương trình có dấu âm, hãy viết biến số tương ứng là một số âm.</p>

    <h2>Đồ Thị</h2>
    <img src="/image/PhuongTrinh/phuong-trinh-bac-hai.jpg" alt="Đồ thị phương trình bậc nhất" style={{ maxWidth: '50%', height: 'auto' }} />
    <img src="/image/PhuongTrinh/cong-thuc-phuong-trinh-bac-hai.jpg" alt="Đồ thị phương trình bậc nhất" style={{ maxWidth: '50%', height: 'auto' }} />


    <div className="container">
      <h2>Máy tính giải phương trình bậc 2 Online</h2>
      <p>Hãy đưa ra biến số:</p>

      <div className="input-container">
        <span>a =</span>
        <input
          type="number"
          value={a}
          onChange={(e) => setA(e.target.value)}
        />
      </div>

      <div className="input-container">
        <span>b =</span>
        <input
          type="number"
          value={b}
          onChange={(e) => setB(e.target.value)}
        />
      </div>

      <div className="input-container">
        <span>c =</span>
        <input
          type="number"
          value={c}
          onChange={(e) => setC(e.target.value)}
        />
      </div>

      <button onClick={calculateSolution}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Phương trình: {a}x² + {b}x + {c} = 0</p>
          <p>Kết quả: {result}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default QuadraticEquation;
