import React, { useState } from 'react';
import '../../components/styles.css'; 

const NthRoot = () => {
  const [x, setX] = useState('');
  const [n, setN] = useState('');
  const [result, setResult] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const calculateNthRoot = () => {
    const value = parseFloat(x);
    const degree = parseFloat(n);
    const nthRootValue = Math.pow(value, 1 / degree);
    setResult(nthRootValue.toFixed(decimalPlaces));
  };

  const clearFields = () => {
    setX('');
    setN('');
    setResult(null);
  };

  return (
    <div class="content">
        <h1>Căn Bậc N</h1>
        
        <h2>Định Nghĩa</h2>
        <p>Trong toán học, căn bậc <em>n</em> của một số <em>x</em> là một số <em>r</em>, mà lũy thừa bậc <em>n</em> của <em>r</em> sẽ bằng <em>x</em>: <strong>r<sup>n</sup> = x</strong>, trong đó <em>n</em> là bậc của căn.</p>
        
        <h2>Khai Căn Bậc N</h2>
        <p>Trong vi tích phân, căn được biểu diễn dưới dạng lũy thừa, trong đó số mũ là một phân số: <strong>∛x = x<sup>1/n</sup></strong>.</p>
        
        <h2>Đồ Thị</h2>
          <img src="/image/LuyThua-KhaiCan/do-thi-khai-can-bac-n.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <h2>Công Thức</h2>
          <img src="/image/LuyThua-KhaiCan/cong-thuc-khai-can-bac-n-300x193.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        
        <p class="note">Lưu ý: Căn bậc n chỉ được định nghĩa cho số không âm khi <em>n</em> là số chẵn và cho tất cả các số thực khi <em>n</em> là số lẻ.</p>

    <div className="container">
      <h2>Máy tính khai căn bậc n Online</h2>
      <p>Hãy đưa ra 2 giá trị</p>

      <div className="input-container">
        <span>x =</span>
        <input
          type="number"
          value={x}
          onChange={(e) => setX(e.target.value)}
        />
      </div>
      
      <div className="input-container">
        <span>n =</span>
        <input
          type="number"
          value={n}
          onChange={(e) => setN(e.target.value)}
        />
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateNthRoot}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Khai căn bậc {n} của {x} là: √{n}{x} = {result}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default NthRoot;
