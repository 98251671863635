import React, { useState } from 'react';
import '../../components/styles.css'; 
import Geometry from '../../Pages/Geometry';

const CircleCalculator = () => {
  const [radius, setRadius] = useState('');
  const [diameter, setDiameter] = useState('');
  const [decimalPlaces, setDecimalPlaces] = useState(2);
  const [area, setArea] = useState(null);
  const [circumference, setCircumference] = useState(null);

  const calculateCircle = () => {
    let r = radius || diameter / 2;
    if (r) {
      const areaResult = Math.PI * r * r;
      const circumferenceResult = 2 * Math.PI * r;
      setArea(areaResult.toFixed(decimalPlaces));
      setCircumference(circumferenceResult.toFixed(decimalPlaces));
    }
  };

  const clearFields = () => {
    setRadius('');
    setDiameter('');
    setArea(null);
    setCircumference(null);
  };

  return (
    <div className="content">
      <h1>Diện Tích và Chu Vi Hình Tròn</h1>
        
        <h2>Chu Vi Hình Tròn</h2>
        <p>Chu vi <em>c</em> của hình tròn bằng chu vi của đường tròn bao quanh nó, tức là bằng <strong>pi</strong> nhân với hai lần bán kính <em>r</em> (đường kính <em>d</em>).</p>
        
        <div class="formula">
            <strong>Công thức tính chu vi hình tròn:</strong>
            <code>C = 2πr</code>
        </div>

        <h2>Diện Tích Hình Tròn</h2>
        <p>Diện tích hình tròn bằng <strong>pi</strong> nhân với bình phương bán kính của đường tròn bao quanh.</p>
        
        <h2>Các Công Thức</h2>
          <img src="/image/DienTichChuVi/dien-tich-va-chu-vi-hinh-tron.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
          <img src="/image/DienTichChuVi/cong-thuc-dien-tich-va-chu-vi-hinh-tron-225x300.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />

        <h2>Các Thành Phần</h2>
        <p>
            <ul>
                <li><strong>P</strong> – chu vi</li>
                <li><strong>S</strong> – diện tích</li>
                <li><strong>r</strong> – bán kính</li>
                <li><strong>d</strong> – đường kính</li>
                <li><strong>S'</strong> – tâm</li>
            </ul>
        </p>

        <p class="note">Lưu ý: Để tính toán chính xác, bạn cần biết bán kính hoặc đường kính của hình tròn.</p>
    
    <div className="container">
      <h2>Máy tính diện tích hình tròn Online</h2>
      <p>Hãy đưa ra 1 giá trị</p>

      <div className="input-container">
        <input
          type="number"
          value={radius}
          onChange={(e) => setRadius(e.target.value)}
          placeholder="r ="
        />
        <span>hoặc</span>
        <input
          type="number"
          value={diameter}
          onChange={(e) => setDiameter(e.target.value)}
          placeholder="d ="
        />
      </div>
      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
          min="0"
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateCircle}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {area !== null && circumference !== null && (
        <div className="result">
          <p>Diện tích S = {area}</p>
          <p>Chu vi P = {circumference}</p>
        </div>
      )}
    </div>
    <Geometry/>
    </div>
  );
};

export default CircleCalculator;
