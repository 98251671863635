import React from 'react';
import '../Pages/styles/styles.css';

const Logarithm = () => {
  const logItems = [
    {
      title: 'Logarit',
      image: 'path-to-image-logarithm.jpg',
      link: '/logarithm/logarithm'
    },
    {
      title: 'Logarit thập phân',
      image: 'path-to-image-logarithm-base10.jpg',
      link: '/logarithm/decimal-log'
    },
    {
      title: 'Logarit tự nhiên',
      image: 'path-to-image-natural-logarithm.jpg',
      link: '/logarithm/natural-log'
    }
  ];

  return (
    <div className='content'>
        <h1>Logarit</h1>
        <div className="geometry-container"> {/* Sử dụng chung CSS với geometry.js */}
            {logItems.map((item, index) => (
                <div key={index} className="geometry-item"> {/* Sử dụng chung CSS */}
                <h2>{item.title}</h2>
                <img src={item.image} alt={item.title} />
                <a href={item.link}>
                    <i className="arrow-icon">➡️</i>
                </a>
                </div>
            ))}
        </div>
    </div>
  );
};

export default Logarithm;
