import React, { useState } from 'react';
import '../../components/styles.css';  

const GasConsumptionCalculator = () => {
  const [gasVolume, setGasVolume] = useState('');
  const [kWhValue, setKWhValue] = useState(null);
  const [MWhValue, setMWhValue] = useState(null);

  // Hàm chuyển đổi m3 sang kWh và MWh
  const convertGasVolume = () => {
    const volume = parseFloat(gasVolume);
    if (isNaN(volume) || volume < 0) {
      setKWhValue(null);
      setMWhValue(null);
      return;
    }

    const kWh = volume * 9.97; // Giả sử 1 m3 khí gas = 9.97 kWh
    const MWh = kWh / 1000; // Chuyển đổi kWh sang MWh
    setKWhValue(kWh);
    setMWhValue(MWh);
  };

  return (
    <div className="container">
      <h2>Máy Tính Tiêu Thụ Khí Gas</h2>

      <div className="input-container">
        <label>Nhập thể tích khí gas (m³):</label>
        <input
          type="number"
          value={gasVolume}
          onChange={(e) => setGasVolume(e.target.value)}
          placeholder="Nhập thể tích (m³)"
        />
      </div>

      <button onClick={convertGasVolume}>Chuyển đổi</button>

      {kWhValue !== null && MWhValue !== null && (
        <div className="results">
          <h4>Kết quả chuyển đổi:</h4>
          <table>
            <thead>
              <tr>
                <th>Thể tích (m³)</th>
                <th>kWh</th>
                <th>MWh</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{gasVolume} m³</td>
                <td>{kWhValue.toFixed(2)} kWh</td>
                <td>{MWhValue.toFixed(4)} MWh</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default GasConsumptionCalculator;
