// Cube.js
import React, { useState } from 'react';
import '../../components/styles.css';

const Cube = () => {
  const [side, setSide] = useState('');
  const [volume, setVolume] = useState(null);
  const [surfaceArea, setSurfaceArea] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const calculateCube = () => {
    const s = parseFloat(side);
    if (s > 0) {
      const volumeValue = Math.pow(s, 3).toFixed(decimalPlaces);
      const surfaceAreaValue = (6 * Math.pow(s, 2)).toFixed(decimalPlaces);
      setVolume(volumeValue);
      setSurfaceArea(surfaceAreaValue);
    } else {
      setVolume('Giá trị không hợp lệ');
      setSurfaceArea('Giá trị không hợp lệ');
    }
  };

  const clearFields = () => {
    setSide('');
    setVolume(null);
    setSurfaceArea(null);
  };

  return (
    <div className='content'>
    <h1>Thể Tích và Diện Tích Khối Lập Phương</h1>
    <p>Cách tính thể tích của hình lập phương rất đơn giản – thông thường, bạn chỉ cần phải tính chiều dài × chiều rộng × chiều cao của hình lập phương. Vì các cạnh của hình lập phương đều có độ dài bằng nhau, một cách khác của công thức thể tích là <strong>V = s<sup>3</sup></strong>, trong đó <strong>s</strong> là độ dài cạnh của hình lập phương.</p>

    <h2>Khái Niệm Khối Lập Phương</h2>
    <p>Khối lập phương là hình khối mà các mặt bên của nó là sáu hình vuông bằng nhau. Đường chéo các mặt bên đều dài bằng nhau. Đường chéo của hình khối cũng dài bằng nhau.</p>

    <h2>Các Công Thức</h2>
    <img src="/image/TheTich-DienTich/the-tich-va-dien-tich-hinh-cau.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/TheTich-DienTich/cong-thuc-the-tich-va-dien-tich-hinh-cau-224x300.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
    <ul>
        <li><strong>V</strong> – thể tích</li>
        <li><strong>A</strong> – diện tích</li>
        <li><strong>a</strong> – các cạnh</li>
        <li><strong>D</strong> – đường chéo hình khối</li>
        <li><strong>d</strong> – đường chéo mặt bên</li>
    </ul>


    <div className="container">
      <h2>Máy tính thể tích và diện tích hình lập phương Online</h2>
      <div className="input-container">
        <span>Cạnh =</span>
        <input type="number" value={side} onChange={(e) => setSide(e.target.value)} />
      </div>
      <div className="decimal-container">
        <label>Làm tròn</label>
        <input type="number" value={decimalPlaces} onChange={(e) => setDecimalPlaces(e.target.value)} />
        <span>số thập phân</span>
      </div>
      <button onClick={calculateCube}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>
      {volume !== null && (
        <div className="result">
          <p>Thể tích V = {volume}</p>
          <p>Diện tích A = {surfaceArea}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default Cube;
