import React, { useState } from 'react';
import '../../components/styles.css'; 

const WeightedMean = () => {
  const [values, setValues] = useState(Array(10).fill(''));
  const [weights, setWeights] = useState(Array(10).fill(''));
  const [rounded, setRounded] = useState(2); 
  const [weightedMean, setWeightedMean] = useState(null);
  

  const calculateWeightedMean = () => {
    let totalWeightedValue = 0;
    let totalWeight = 0;

    for (let i = 0; i < values.length; i++) {
      const value = parseFloat(values[i]);
      const weight = parseFloat(weights[i]);
      if (!isNaN(value) && !isNaN(weight)) {
        totalWeightedValue += value * weight;
        totalWeight += weight;
      }
    }

    if (totalWeight > 0) {
      const mean = totalWeightedValue / totalWeight;
      setWeightedMean(mean.toFixed(rounded));
    } else {
      setWeightedMean('Trọng số phải lớn hơn 0');
    }
  };

  const handleValueChange = (index, newValue) => {
    const updatedValues = [...values];
    updatedValues[index] = newValue;
    setValues(updatedValues);
  };

  const handleWeightChange = (index, newWeight) => {
    const updatedWeights = [...weights];
    updatedWeights[index] = newWeight;
    setWeights(updatedWeights);
  };
  const clearFields = () => {
    setValues('');
    setWeights('');
    setRounded('');
    setWeightedMean(null);
  };

  return (
    <div className='content'>
      <h1>Trung bình cộng có trọng số</h1>
      <p>Trung bình cộng gia quyền được sử dụng trong trường hợp mỗi phần tử có tầm quan trọng khác nhau – giá trị mà cần được gắn một trọng số.

      Trung bình cộng có trọng số, hay số bình quân gia quyền, của một tập là giá trị trung bình cộng có phản ánh tầm quan trọng của các phần tử (hay giá trị quan sát) trong tập đó. Mỗi một giá trị quan sát sẽ được gắn một trọng số.</p>
      <h2>Công thức</h2>
      <img src="/image/TrungBinh/trung-binh-cong-co-trong-so.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
    
    <div className="container">
      <h2>Máy Tính Trung Bình Cộng Có Trọng Số Online</h2>

      <h3>Hãy đưa ra các giá trị để tính trung bình</h3>

      {values.map((value, index) => (
        <div className="input-container" key={index}>
          <span>{index + 1}:</span>
          <input
            type="number"
            value={value}
            onChange={(e) => handleValueChange(index, e.target.value)}
            placeholder={`Giá trị x${index + 1}`}
          />
          <input
            type="number"
            value={weights[index]}
            onChange={(e) => handleWeightChange(index, e.target.value)}
            placeholder={`Trọng số p${index + 1}`}
          />
        </div>
      ))}

      <div className="rounded-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={rounded}
          onChange={(e) => setRounded(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateWeightedMean}>Tính trung bình cộng</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {weightedMean !== null && (
        <div className="result">
          <p>Trung bình cộng có trọng số: {weightedMean}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default WeightedMean;
