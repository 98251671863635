import React, { useState } from 'react';
import '../../components/styles.css'; 

const LinearEquation = () => {
  const [a, setA] = useState(''); // Hệ số a
  const [b, setB] = useState(''); // Hệ số b
  const [result, setResult] = useState(null); // Kết quả

  // Hàm tính nghiệm x của phương trình bậc nhất
  const calculateSolution = () => {
    const coeffA = parseFloat(a);
    const coeffB = parseFloat(b);

    if (isNaN(coeffA) || isNaN(coeffB)) {
      setResult('Vui lòng nhập số hợp lệ');
      return;
    }

    if (coeffA === 0) {
      setResult('Hệ số a không được bằng 0');
      return;
    }

    const x = -coeffB / coeffA;
    setResult(x.toFixed(2));
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setA('');
    setB('');
    setResult(null);
  };

  return (
    <div className="content">
      <h1>Phương Trình Bậc Nhất</h1>
      <h2>Định Nghĩa</h2>
      <p>Phương trình <strong>ax + b = 0</strong>, với a và b là hai số đã cho và a ≠ 0 được gọi là phương trình bậc nhất một ẩn.</p>

      <h2>Bảng Tính Giải Phương Trình Bậc Một</h2>
      <p>Hãy đưa ra phương trình theo dạng mẫu chung. Trường hợp trong phương trình có dấu âm, hãy viết biến số tương ứng là một số âm.</p>

      <h2>Đồ Thị</h2>
      <img src="/image/PhuongTrinh/phuong-trinh-bac-nhat.jpg" alt="Đồ thị phương trình bậc nhất" style={{ maxWidth: '50%', height: 'auto' }} />
      
      
      <div className="container">
        <h2>Máy tính giải phương trình bậc 1 Online</h2>
        <p>Hãy đưa ra biến số:</p>

        <div className="input-container">
          <span>a =</span>
          <input
            type="number"
            value={a}
            onChange={(e) => setA(e.target.value)}
          />
        </div>

        <div className="input-container">
          <span>x +</span>
          <span>b =</span>
          <input
            type="number"
            value={b}
            onChange={(e) => setB(e.target.value)}
          />
        </div>

        <button onClick={calculateSolution}>Tính</button>
        <button className="clear-button" onClick={clearFields}>Xóa</button>

        {result !== null && (
          <div className="result">
            <p>Nghiệm của phương trình {a}x + {b} = 0 là: x = {result}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LinearEquation;
