import React, { useState } from 'react';
import '../../components/styles.css'; 

const NaturalLogarithmCalculator = () => {
  const [value, setValue] = useState('');
  const [lnResult, setLnResult] = useState(null);
  const [roundedDecimal, setRoundedDecimal] = useState(2);

  // Function to calculate natural logarithm (ln)
  const calculateNaturalLog = () => {
    if (value > 0) {
      const result = Math.log(value); // Natural logarithm using base 'e'
      setLnResult(result.toFixed(roundedDecimal));
    } else {
      setLnResult('Giá trị không hợp lệ');
    }
  };

  return (
    <div className="container">
      <h2>Máy Tính Giải Logarit Tự Nhiên Online</h2>

      <h3>Hãy đưa ra giá trị x</h3>
      <div className="input-container">
        <label>x =</label>
        <input
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Nhập giá trị x"
        />
      </div>

      <div className="rounded-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={roundedDecimal}
          onChange={(e) => setRoundedDecimal(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateNaturalLog}>Tính ln x</button>

      {lnResult !== null && (
        <div className="result">
          <p>ln x = {lnResult}</p>
        </div>
      )}
    </div>
  );
};

export default NaturalLogarithmCalculator;
