import React from 'react';
import '../Pages/styles/styles.css';

const VolumeAndArea = () => {
  const volumeAndAreaItems = [
    {
      title: 'Hình chóp',
      image: 'path-to-image-pyramid.jpg',
      link: '/volume-area/pyramid'
    },
    {
      title: 'Hình lăng trụ',
      image: 'path-to-image-prism.jpg',
      link: '/volume-area/prism'
    },
    {
      title: 'Hình cầu',
      image: 'path-to-image-sphere.jpg',
      link: '/volume-area/sphere'
    },
    {
      title: 'Hình nón',
      image: 'path-to-image-cone.jpg',
      link: '/volume-area/cone'
    },
    {
      title: 'Hình trụ tròn',
      image: 'path-to-image-cylinder.jpg',
      link: '/volume-area/cylinder'
    },
    {
      title: 'Hình hộp chữ nhật',
      image: 'path-to-image-rectangular-box.jpg',
      link: '/volume-area/rectangular-box'
    },
    {
      title: 'Khối lập phương',
      image: 'path-to-image-cube.jpg',
      link: '/volume-area/cube'
    }
  ];

  return (
    <div className='content'>
        <h1>Thể tích và Diện tích</h1>
        <div className="geometry-container"> 
            {volumeAndAreaItems.map((item, index) => (
                <div key={index} className="geometry-item"> 
                <h2>{item.title}</h2>
                <img src={item.image} alt={item.title} />
                <a href={item.link}>
                    <i className="arrow-icon">➡️</i>
                </a>
                </div>
            ))}
        </div>
    </div>
  );
};

export default VolumeAndArea;
