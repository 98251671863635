import React from 'react';
import '../Pages/styles/styles.css';

const Home = () => {
  return (
    <div className='content'>
      <h1>Chào mừng đến với Trang web Tính toán Online</h1>
      <p>
        Trang web của chúng tôi cung cấp các công cụ tính toán trực tuyến hữu ích, giúp bạn dễ dàng thực hiện 
        các phép tính phức tạp một cách nhanh chóng và chính xác. Dù bạn là học sinh, sinh viên, hay là người 
        làm việc trong các lĩnh vực kỹ thuật, chúng tôi đều có những công cụ phù hợp để hỗ trợ bạn.
      </p>
      <p>
        Các công cụ mà chúng tôi cung cấp bao gồm:
        <ul>
          <li>Máy tính toán cơ bản và nâng cao</li>
          <li>Công cụ tính diện tích, thể tích</li>
          <li>Chuyển đổi đơn vị đo lường</li>
          <li>Công cụ tính phương trình và hàm số</li>
          <li>Chuyển đổi năng lượng và nhiên liệu</li>
          <li>Các công cụ tính lượng giác, logarit và nhiều hơn nữa</li>
        </ul>
      </p>
      <p>
        Với giao diện thân thiện và dễ sử dụng, trang web của chúng tôi cho phép bạn thao tác dễ dàng trên các công cụ, 
        mang lại sự tiện lợi và hiệu quả cao. Hãy khám phá và trải nghiệm các tính năng vượt trội mà chúng tôi mang đến!
      </p>
    </div>
  );
};

export default Home;

