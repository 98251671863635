import React, { useState } from 'react';
import '../../components/styles.css'; 

const Pentagon = () => {
  const [a, setA] = useState('');
  const [R, setR] = useState('');
  const [r, setRsmall] = useState('');
  const [perimeter, setPerimeter] = useState(null);
  const [area, setArea] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  // Hàm tính chu vi và diện tích ngũ giác đều
  const calculatePentagon = () => {
    const side = parseFloat(a);
    const radiusR = parseFloat(R);
    const radiusr = parseFloat(r);

    // Tính chu vi
    const P = side * 5; // Chu vi

    // Tính diện tích
    const S = (5 / 2) * side * radiusr; // Diện tích

    setArea(S.toFixed(decimalPlaces));
    setPerimeter(P.toFixed(decimalPlaces));
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setA('');
    setR('');
    setRsmall('');
    setPerimeter(null);
    setArea(null);
  };

  return (
    <div class="content">
        <h1>Diện Tích và Chu Vi Ngũ Giác Đều</h1>
        
        <h2>Cách Tính Chu Vi</h2>
        <p>Chu vi ngũ giác đều được tính bằng năm lần độ dài của một cạnh.</p>
        

        <h2>Cách Tính Diện Tích</h2>
        <p>Diện tích ngũ giác đều được tính bằng công thức dựa trên bán kính đường tròn ngoại tiếp.</p>
        
        <h2>Các Công Thức</h2>
        <img src="/image/DienTichChuVi/dien-tich-chu-vi-ngu-giac-deu-300x300.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/DienTichChuVi/cong-thuc-dien-tich-chu-vi-ngu-giac-deu-254x300.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />

        <h2>Định Nghĩa Ngũ Giác Đều</h2>
        <p>
            <ul>
                <li>Ngũ giác đều là đa giác với năm cạnh bằng nhau.</li>
                <li>Các cạnh liền kề tạo thành góc 108°.</li>
            </ul>
        </p>

        <h2>Các Thành Phần</h2>
        <p>
            <ul>
                <li><strong>P</strong> – chu vi</li>
                <li><strong>S</strong> – diện tích</li>
                <li><strong>a</strong> – chiều dài các cạnh</li>
                <li><strong>R</strong> – bán kính đường tròn ngoại tiếp</li>
                <li><strong>r</strong> – bán kính đường tròn nội tiếp</li>
                <li><strong>S′</strong> – tâm ngũ giác</li>
                <li><strong>K</strong> – đường tròn ngoại tiếp</li>
                <li><strong>k</strong> – đường tròn nội tiếp</li>
            </ul>
        </p>

        <p class="note">Lưu ý: Để tính toán chính xác, bạn cần biết độ dài các cạnh và bán kính của các đường tròn.</p>

    <div className="container">
      <h2>Máy tính diện tích hình ngũ giác Online</h2>
      <p>Hãy đưa ra 1 giá trị</p>

      <div className="input-container">
        <span>a =</span>
        <input
          type="number"
          value={a}
          onChange={(e) => setA(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>R =</span>
        <input
          type="number"
          value={R}
          onChange={(e) => setR(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>r =</span>
        <input
          type="number"
          value={r}
          onChange={(e) => setRsmall(e.target.value)}
        />
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculatePentagon}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {perimeter !== null && area !== null && (
        <div className="result">
          <p>Chu vi P = {perimeter}</p>
          <p>Diện tích S = {area}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default Pentagon;
