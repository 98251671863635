// Pyramid.js
import React, { useState } from 'react';
import '../../components/styles.css';

const Pyramid = () => {
  const [baseArea, setBaseArea] = useState('');
  const [height, setHeight] = useState('');
  const [volume, setVolume] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const calculatePyramid = () => {
    const base = parseFloat(baseArea);
    const h = parseFloat(height);
    if (base > 0 && h > 0) {
      setVolume(((1 / 3) * base * h).toFixed(decimalPlaces));
    } else {
      setVolume('Giá trị không hợp lệ');
    }
  };

  const clearFields = () => {
    setBaseArea('');
    setHeight('');
    setVolume(null);
  };

  return (
    <div className='content'>
    <h1>Thể Tích và Diện Tích Hình Chóp</h1>
    <p>Công thức tính thể tích khối chóp.</p>

    <h2>Công Thức Tính Thể Tích Hình Chóp</h2>
    <p><strong>V = (Ad · h) / 3</strong></p>
    <p>Trong đó: <strong>Ad</strong> là diện tích đáy, <strong>h</strong> là chiều cao của khối chóp (khoảng cách từ đỉnh đến mặt đáy).</p>

    <h2>Định Nghĩa Hình Chóp</h2>
    <p>Mặt đáy của hình chóp là một đa giác. Tất cả các đỉnh của đa giác được nối với đỉnh của hình chóp – một điểm nằm ngoài mặt phẳng của đáy.</p>

    <h2>Bảng Tính Hình Chóp Đều</h2>
    <p>Hình chóp đều là hình chóp mà mặt đáy của nó có các cạnh đều bằng nhau.</p>

    <h2>Các Công Thức</h2>
    <img src="/image/TheTich-DienTich/the-tich-va-dien-tich-hinh-cau.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/TheTich-DienTich/cong-thuc-the-tich-va-dien-tich-hinh-cau-224x300.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />

    <h2>Các Thành Phần</h2>
    <ul>
        <li><strong>V</strong> – thể tích</li>
        <li><strong>A</strong> – diện tích</li>
        <li><strong>Ad</strong> – diện tích đáy</li>
        <li><strong>Axq</strong> – diện tích xung quanh</li>
        <li><strong>h</strong> – chiều cao</li>
        <li><strong>s</strong> – chiều dài cạnh bên</li>
        <li><strong>α</strong> – góc tạo bởi mặt bên và mặt đáy</li>
    </ul>


    <div className="container">
      <h2>Máy tính thể tích hình chóp Online</h2>
      <div className="input-container">
        <span>Diện tích đáy =</span>
        <input type="number" value={baseArea} onChange={(e) => setBaseArea(e.target.value)} />
      </div>
      <div className="input-container">
        <span>Chiều cao =</span>
        <input type="number" value={height} onChange={(e) => setHeight(e.target.value)} />
      </div>
      <div className="decimal-container">
        <label>Làm tròn</label>
        <input type="number" value={decimalPlaces} onChange={(e) => setDecimalPlaces(e.target.value)} />
        <span>số thập phân</span>
      </div>
      <button onClick={calculatePyramid}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>
      {volume !== null && <div className="result">Thể tích V = {volume}</div>}
    </div>
    </div>
  );
};

export default Pyramid;
