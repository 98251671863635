import React from 'react';
import '../Pages/styles/styles.css';

const TrigonometricFunctions = () => {
  const trigItems = [
    {
      title: 'Cotang',
      image: 'path-to-image-cotangent.jpg',
      link: '/trigonometric/cot'
    },
    {
      title: 'Tang',
      image: 'path-to-image-tangent.jpg',
      link: '/trigonometric/tan'
    },
    {
      title: 'Cos',
      image: 'path-to-image-cosine.jpg',
      link: '/trigonometric/cos'
    },
    {
      title: 'Sin',
      image: 'path-to-image-sine.jpg',
      link: '/trigonometric/sin'
    }
  ];

  return (
    <div className='content'>
        <h1>Hàm lượng giác</h1>
        <div className="geometry-container"> {/* Sử dụng chung CSS với geometry.js */}
            {trigItems.map((item, index) => (
                <div key={index} className="geometry-item"> {/* Sử dụng chung CSS */}
                <h2>{item.title}</h2>
                <img src={item.image} alt={item.title} />
                <a href={item.link}>
                    <i className="arrow-icon">➡️</i>
                </a>
                </div>
            ))}
        </div>
    </div>
  );
};

export default TrigonometricFunctions;
