import React, { useState } from 'react';
import '../../components/styles.css'; 

const CubeRoot = () => {
  const [x, setX] = useState('');
  const [result, setResult] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const calculateCubeRoot = () => {
    const value = parseFloat(x);
    const cubeRootValue = Math.cbrt(value);
    setResult(cubeRootValue.toFixed(decimalPlaces));
  };

  const clearFields = () => {
    setX('');
    setResult(null);
  };

  return (
    <div class="content">
        <h1>Căn Bậc Ba</h1>
        
        <h2>Định Nghĩa</h2>
        <p>Căn bậc ba của một số <em>a</em> là số <em>x</em> sao cho <em>x<sup>3</sup> = a</em>. Căn bậc ba của số <em>a</em> được ký hiệu là <strong>∛a</strong>. Như vậy, <strong>(∛a)<sup>3</sup> = a</strong> và <strong>(a<sup>3</sup>)<sup>1/3</sup> = a</strong>.</p>
        
        <h2>Khai Căn Bậc Ba</h2>
        <p>Đồ thị của hàm số <em>y = ∛x</em> là một đường cong, có dạng hình chóp, đi qua gốc tọa độ (0, 0) và có chiều hướng tăng dần.</p>
        
        <h2>Đồ Thị</h2>
          <img src="/image/LuyThua-KhaiCan/do-thi-khai-can-bac-3.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <h2>Công Thức</h2>
          <img src="/image/LuyThua-KhaiCan/cong-thuc-khai-can-bac-3-300x193.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        
        <p class="note">Lưu ý: Hàm số căn bậc ba được định nghĩa cho tất cả các số thực <em>x</em>.</p>

    <div className="container">
      <h2>Máy tính khai căn bậc ba Online</h2>
      <p>Hãy đưa ra 1 giá trị</p>

      <div className="input-container">
        <span>x =</span>
        <input
          type="number"
          value={x}
          onChange={(e) => setX(e.target.value)}
        />
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateCubeRoot}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Khai căn bậc ba của {x} là: ∛{x} = {result}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default CubeRoot;
