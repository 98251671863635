import React, { useState } from 'react';
import '../../components/styles.css'; 

const CubePower = () => {
  const [x, setX] = useState('');
  const [result, setResult] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const calculateCubePower = () => {
    const value = parseFloat(x);
    const cubeValue = Math.pow(value, 3);
    setResult(cubeValue.toFixed(decimalPlaces));
  };

  const clearFields = () => {
    setX('');
    setResult(null);
  };

  return (
    <div class="content">
        <h1>Lũy Thừa Bậc Ba</h1>
        
        <h2>Định Nghĩa</h2>
        <p>Lũy thừa bậc ba của một số là phép toán nhân ba thừa số là chính số đó với nhau.</p>
        
        <h2>Lũy Thừa của Không và Một</h2>
        <p>
            <strong>0<sup>n</sup> = 0</strong>
            <strong>1<sup>n</sup> = 1</strong>
        </p>
        
        <h2>Đồ Thị</h2>
          <img src="/image/LuyThua-KhaiCan/do-thi-luy-thua-bac-3.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <h2>Công Thức</h2>
          <img src="/image/LuyThua-KhaiCan/cong-thuc-luy-thua-bac-3.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />

    <div className="container">
      <h2>Máy tính lũy thừa bậc ba Online</h2>
      <p>Hãy đưa ra 1 giá trị</p>

      <div className="input-container">
        <span>x =</span>
        <input
          type="number"
          value={x}
          onChange={(e) => setX(e.target.value)}
        />
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateCubePower}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>{x} lũy thừa bậc ba là: {x}³ = {result}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default CubePower;
