import React, { useState } from 'react';
import '../../components/styles.css'; 

const Square = () => {
  const [side, setSide] = useState('');
  const [area, setArea] = useState(null);
  const [perimeter, setPerimeter] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  // Hàm tính chu vi và diện tích hình vuông
  const calculateSquare = () => {
    const a = parseFloat(side);

    // Tính chu vi và diện tích
    const P = a * 4; // Chu vi
    const S = a * a; // Diện tích

    setPerimeter(P.toFixed(decimalPlaces));
    setArea(S.toFixed(decimalPlaces));
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setSide('');
    setPerimeter(null);
    setArea(null);
  };

  return (
    <div class="content">
        <h1>Diện Tích và Chu Vi Hình Vuông</h1>
        
        <h2>Chu Vi Hình Vuông</h2>
        <p>Chu vi hình vuông là tổng độ dài bốn cạnh của nó hay gấp bốn lần độ dài của một cạnh.</p>
        
        <h2>Diện Tích Hình Vuông</h2>
        <p>Diện tích hình vuông là bình phương độ dài của cạnh trong hình vuông.</p>
        
        <h2>Các Công Thức</h2>
        <img src="/image/DienTichChuVi/dien-tich-va-chu-vi-hinh-vuong.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/DienTichChuVi/cong-thuc-dien-tich-va-chu-vi-hinh-vuong-300x196.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />

        <h2>Cách Tính Diện Tích</h2>

        <h2>Định Nghĩa Hình Vuông</h2>
        <p>
            <ul>
                <li>Các cạnh của hình vuông có chiều dài bằng nhau, các cạnh liền kề tạo thành góc vuông.</li>
                <li>Đường chéo có độ dài bằng nhau, cắt nhau tại điểm giữa và vuông góc với nhau.</li>
            </ul>
        </p>

        <h2>Các Thành Phần</h2>
        <p>
            <ul>
                <li><strong>P</strong> – chu vi</li>
                <li><strong>S</strong> – diện tích</li>
                <li><strong>a</strong> – độ dài các cạnh</li>
                <li><strong>d</strong> – độ dài đường chéo</li>
            </ul>
        </p>

        <p class="note">Lưu ý: Để tính toán chính xác, bạn cần biết độ dài các cạnh của hình vuông.</p>


    <div className="container">
      <h2>Máy tính diện tích và chu vi hình vuông Online</h2>
      <p>Hãy đưa ra 1 giá trị</p>

      <div className="input-container">
        <span>a =</span>
        <input
          type="number"
          value={side}
          onChange={(e) => setSide(e.target.value)}
        />
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateSquare}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {perimeter !== null && area !== null && (
        <div className="result">
          <p>Chu vi P = {perimeter}</p>
          <p>Diện tích S = {area}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default Square;
