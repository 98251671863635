import React, { useState } from 'react';
import '../../components/styles.css';

const PressureConverter = () => {
  const [pressureValue, setPressureValue] = useState('');
  const [fromUnit, setFromUnit] = useState('Pa');
  const [toUnit, setToUnit] = useState('Pa');
  const [result, setResult] = useState(null);

  const convertPressure = () => {
    const value = parseFloat(pressureValue);
    let convertedValue;

    // Chuyển đổi giữa các đơn vị
    const conversionRates = {
      Pa: 1,
      hPa: 100,
      kPa: 1000,
      MPa: 1_000_000,
      bar: 100_000,
      atm: 101_325,
      torr: 101_325 / 760,
    };

    // Chuyển đổi từ đơn vị gốc sang pascal
    const valueInPa = value * conversionRates[fromUnit];

    // Chuyển đổi từ pascal sang đơn vị đích
    convertedValue = valueInPa / conversionRates[toUnit];

    if (convertedValue !== undefined) {
      setResult(convertedValue);
    }
  };

  const clearFields = () => {
    setPressureValue('');
    setFromUnit('Pa');
    setToUnit('Pa');
    setResult(null);
  };

  return (
    <div className="container">
      <h2>Máy tính chuyển đổi áp suất</h2>
      <p>Hãy đưa ra giá trị áp suất và chọn đơn vị</p>
      <input
        type="number"
        value={pressureValue}
        onChange={(e) => setPressureValue(e.target.value)}
        placeholder="Nhập giá trị"
      />
      <select value={fromUnit} onChange={(e) => setFromUnit(e.target.value)}>
        <option value="Pa">pascal (Pa)</option>
        <option value="hPa">héctôpascal (hPa)</option>
        <option value="kPa">kilôpascal (kPa)</option>
        <option value="MPa">mêgapascal (MPa)</option>
        <option value="bar">bar</option>
        <option value="atm">átmốtphe (atm)</option>
        <option value="torr">torr (torr, mm Hg)</option>
      </select>
      <span>đến</span>
      <select value={toUnit} onChange={(e) => setToUnit(e.target.value)}>
        <option value="Pa">pascal (Pa)</option>
        <option value="hPa">héctôpascal (hPa)</option>
        <option value="kPa">kilôpascal (kPa)</option>
        <option value="MPa">mêgapascal (MPa)</option>
        <option value="bar">bar</option>
        <option value="atm">átmốtphe (atm)</option>
        <option value="torr">torr (torr, mm Hg)</option>
      </select>

      <button onClick={convertPressure}>Chuyển đổi</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Kết quả: {result.toFixed(2)} {toUnit}</p>
        </div>
      )}
    </div>
  );
};

export default PressureConverter;
