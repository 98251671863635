import React, { useState } from 'react';
import '../../components/styles.css'; 

const CotanCalculator = () => {
  const [angle, setAngle] = useState('');
  const [cotanValue, setCotanValue] = useState(null);
  const [roundedCotan, setRoundedCotan] = useState(6);
  const [alphaValue, setAlphaValue] = useState('');
  const [angleUnit, setAngleUnit] = useState('deg'); // 'deg' cho độ, 'rad' cho radian

  // Hàm tính giá trị cotan
  const calculateCotan = () => {
    let radians = angleUnit === 'deg' ? (angle * Math.PI) / 180 : angle;
    const cotanResult = 1 / Math.tan(radians); // cotan = 1/tan
    setCotanValue(cotanResult.toFixed(roundedCotan));
  };

  // Hàm tính giá trị ngược lại (cot^-1)
  const calculateInverseCotan = () => {
    const value = parseFloat(alphaValue);
    if (isNaN(value)) {
      setAlphaValue('Giá trị không hợp lệ');
    } else {
      const angleInRad = Math.atan(1 / value); // Tính arccot
      const angleInDeg = (angleInRad * 180) / Math.PI; // Chuyển đổi sang độ
      setAlphaValue(angleInDeg.toFixed(4) + '°');
    }
  };

  return (
    <div className="container">
      <h2>Máy Tính Giải Cotan Online</h2>

      <h3>Hãy viết góc α</h3>
      <div className="input-container">
        <span>α =</span>
        <input
          type="number"
          value={angle}
          onChange={(e) => setAngle(e.target.value)}
        />
        <select value={angleUnit} onChange={(e) => setAngleUnit(e.target.value)}>
          <option value="deg">°</option>
          <option value="rad">rad</option>
        </select>
      </div>
      
      <div className="rounded-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={roundedCotan}
          onChange={(e) => setRoundedCotan(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateCotan}>Tính cotan α</button>
      
      {cotanValue !== null && (
        <div className="result">
          <p>cotan α = {cotanValue}</p>
        </div>
      )}

      <h3>Hãy cho giá trị cotan α và chọn đơn vị góc</h3>
      <div className="input-container">
        <span>cotan α =</span>
        <input
          type="number"
          value={alphaValue}
          onChange={(e) => setAlphaValue(e.target.value)}
        />
      </div>
      <button onClick={calculateInverseCotan}>Tính α từ cotan α</button>
      
      {alphaValue && (
        <div className="result">
          <p>α = {alphaValue}</p>
        </div>
      )}
    </div>
  );
};

export default CotanCalculator;
