import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import './App.css';
import Home from './Pages/Home';
import PercentageCalculator from './Pages/PercentageCalculator';
import Calculator from './Pages/Calculator';
import Circle from './components/Geometry/Circle';
import Square from './components/Geometry/Square';
import Triangle from './components/Geometry/Triangle';
import RightTriangle from './components/Geometry/RightTriangle';
import Rectangle from './components/Geometry/Rectangle';
import Rhombus from './components/Geometry/Rhombus';
import Parallelogram from './components/Geometry/Parallelogram';
import Trapezoid from './components/Geometry/Trapezoid';
import Pentagon from './components/Geometry/Pentagon';
import Header from './components/Header';
import Hexagon from './components/Geometry/Hexagon';
import Polygon from './components/Geometry/Polygon';
import PythagoreanTheorem from './components/Geometry/PythagoreanTheorem';
import SquareRoot from './components/Exponentiation/SquareRoot';
import SquarePower from './components/Exponentiation/SquareExponent';
import CubeRoot from './components/Exponentiation/CubicRoot';
import CubePower from './components/Exponentiation/CubicEcponent';
import NthRoot from './components/Exponentiation/NthRoot';
import NthPower from './components/Exponentiation/NthExponent';
import LinearSystem from './components/Equations/system';
import LinearEquation from './components/Equations/Quadratic';
import Linear from './components/Equations/Linear';
import Sphere from './components/VolumeArea/Sphere';
import Cone from './components/VolumeArea/Cone';
import Cube from './components/VolumeArea/Cube';
import Cylinder from './components/VolumeArea/Cylinder';
import Prism from './components/VolumeArea/Prism';
import Pyramid from './components/VolumeArea/Pyramid';
import RectangularBox from './components/VolumeArea/RectangularPrism';
import Sin from './components/Trigonometry/Sin';
import CosCalculator from './components/Trigonometry/Cos';
import TanCalculator from './components/Trigonometry/Tan';
import CotanCalculator from './components/Trigonometry/Cot';
import SimpleMean from './components/Average/SimoleMean';
import WeightedMean from './components/Average/WeightedMean';
import NaturalLogarithmCalculator from './components/Logarithm/NaturalLog';
import LogarithmCalculator from './components/Logarithm/Logarithm';
import DecimalLogarithmCalculator from './components/Logarithm/DecimalLog';
import ElectricityCalculator from './components/EnergyFuel/Electricity';
import FuelConsumptionCalculator from './components/EnergyFuel/Fuel';
import GasConsumptionCalculator from './components/EnergyFuel/Gas';
import LengthConverter from './components/UnitConversion/Length';
import AreaConverter from './components/UnitConversion/Area';
import VolumeConverter from './components/UnitConversion/Volume';
import MassConverter from './components/UnitConversion/Mass';
import SpeedConverter from './components/UnitConversion/Speed';
import TemperatureConverter from './components/UnitConversion/Temperature';
import AngleConverter from './components/UnitConversion/Angle';
import PowerConverter from './components/UnitConversion/Power';
import PressureConverter from './components/UnitConversion/Pressure';
import EnergyConverter from './components/UnitConversion/Energy';
import TimeConverter from './components/UnitConversion/Time';
import Geometry from './Pages/Geometry';
import Exponentiation from './Pages/Exponentiation';
import Equations from './Pages/Equations';
import Average from './Pages/Average';
import VolumeAndArea from './Pages/VolumeArea';
import TrigonometricFunctions from './Pages/Trigonometric';
import Logarithm from './Pages/Logarithm';
import EnergyAndFuel from './Pages/EnergyFuel';
import UnitConversion from './Pages/UnitConversion';
import Menu from './Pages/Menu';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Footer from './components/Footer';



const App = () => {
  return (
    <div>
    <Router>
      <div className="app">
        <Header />
        <div className="main-layout">
          <Sidebar />
          <div className="main-content">
            <Routes>
              <Route path="/calculator" element={<Calculator />} />
              <Route path="/home" element={<Home />} />

              <Route path="/percentage" element={<PercentageCalculator />}/>
              <Route path="/geometry" element={<Geometry />}/>
              <Route path="/exponentiation" element={<Exponentiation />}/>
              <Route path="/equations" element={<Equations />}/>
              <Route path="/average" element={<Average/>}/>
              <Route path="/volume-area" element={<VolumeAndArea/>}/>
              <Route path="/trigonometric" element={<TrigonometricFunctions/>}/>
              <Route path="/logarithm" element={<Logarithm/>}/>
              <Route path="/energy-fuel" element={<EnergyAndFuel/>}/>
              <Route path="/unit-conversion" element={<UnitConversion/>}/>
              <Route path="/menu" element={<Menu/>}/>
              <Route path="/contact" element={<Contact/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/about" element={<Footer/>}/>



              <Route path="/geometry/circle" element={<Circle />} />
              <Route path="/geometry/square" element={<Square />} />
              <Route path="/geometry/triangle" element={<Triangle />} />
              <Route path="/geometry/righttriangle" element={<RightTriangle />} />
              <Route path="/geometry/rectangle" element={<Rectangle />} />
              <Route path="/geometry/rhombus" element={<Rhombus />} />
              <Route path="/geometry/parallelogram" element={<Parallelogram />} />
              <Route path="/geometry/trapezoid" element={<Trapezoid />} />
              <Route path="/geometry/pentagon" element={<Pentagon />} />
              <Route path="/geometry/hexagon" element={<Hexagon />} />
              <Route path="/geometry/polygon" element={<Polygon />} />
              <Route path="/geometry/pythagoreanTheorem" element={<PythagoreanTheorem />} />

              <Route path="/exponentiation/square-root" element={<SquareRoot />} />
              <Route path="/exponentiation/square-exponent" element={<SquarePower />} />
              <Route path="/exponentiation/cubic-root" element={<CubeRoot />} />
              <Route path="/exponentiation/cubic-exponent" element={<CubePower />} />
              <Route path="/exponentiation/nth-root" element={<NthRoot />} />
              <Route path="/exponentiation/nth-exponent" element={<NthPower />} />

              <Route path="/equations/system" element={<LinearSystem />} />
              <Route path="/equations/quadratic" element={<LinearEquation />} />
              <Route path="/equations/linear" element={<Linear />} />


              <Route path="/volume-area/sphere" element={<Sphere />} />
              <Route path="/volume-area/cone" element={<Cone />} />
              <Route path="/volume-area/cube" element={<Cube />} />
              <Route path="/volume-area/cylinder" element={<Cylinder />} />
              <Route path="/volume-area/prism" element={<Prism />} />
              <Route path="/volume-area/pyramid" element={<Pyramid />} />
              <Route path="/volume-area/rectangular-box" element={<RectangularBox />} />

              <Route path="/trigonometric/sin" element={<Sin />} />
              <Route path="/trigonometric/cos" element={<CosCalculator />} />
              <Route path="/trigonometric/tan" element={<TanCalculator/>} />
              <Route path="/trigonometric/cot" element={<CotanCalculator />} />

              <Route path="/average/simple-mean" element={<SimpleMean />} />
              <Route path="/average/weighted-mean" element={<WeightedMean />} />

              <Route path="/logarithm/logarithm" element={<LogarithmCalculator />} />
              <Route path="/logarithm/natural-log" element={<NaturalLogarithmCalculator />} />
              <Route path="/logarithm/decimal-log" element={<DecimalLogarithmCalculator />} />
              
              <Route path="/energy-fuel/electricity-consumption" element={<ElectricityCalculator />} />
              <Route path="/energy-fuel/fuel-consumption" element={<FuelConsumptionCalculator />} />
              <Route path="/energy-fuel/gas-consumption" element={<GasConsumptionCalculator />} />
              
              <Route path="/unit-conversion/length" element={<LengthConverter />} />
              <Route path="/unit-conversion/area" element={<AreaConverter />} />
              <Route path="/unit-conversion/volume" element={<VolumeConverter />} />
              <Route path="/unit-conversion/mass" element={<MassConverter />} />
              <Route path="/unit-conversion/speed" element={<SpeedConverter />} />
              <Route path="/unit-conversion/temperature" element={<TemperatureConverter />} />
              <Route path="/unit-conversion/angle" element={<AngleConverter />} />
              <Route path="/unit-conversion/power" element={<PowerConverter />} />
              <Route path="/unit-conversion/pressure" element={<PressureConverter />} />
              <Route path="/unit-conversion/energy" element={<EnergyConverter />} />
              <Route path="/unit-conversion/time" element={<TimeConverter />} />


            </Routes>
          </div>
        </div>
        
      </div>
      
    </Router>
    </div>   
  );
};

export default App;
