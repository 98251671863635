import React from 'react';
import './MyButton.css';

const MyButton = ({ onClick, children, className }) => {
  return (
    <div className={`my-button ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

export default MyButton;
