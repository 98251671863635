import React, { useState } from 'react';
import '../../components/styles.css'; 

const RhombusCalculator = () => {
  const [side, setSide] = useState('');
  const [height, setHeight] = useState('');
  const [diagonal1, setDiagonal1] = useState('');
  const [diagonal2, setDiagonal2] = useState('');
  const [angleAlpha, setAngleAlpha] = useState('');
  const [angleUnitAlpha, setAngleUnitAlpha] = useState('rad');
  const [angleBeta, setAngleBeta] = useState('');
  const [angleUnitBeta, setAngleUnitBeta] = useState('rad');
  const [decimalPlaces, setDecimalPlaces] = useState(2);
  const [perimeter, setPerimeter] = useState(null);
  const [area, setArea] = useState(null);

  // Hàm chuyển đổi giữa radian và độ
  const convertAngle = (angle, unit) => {
    return unit === 'rad' ? angle : (angle * Math.PI) / 180; // Chuyển độ sang radian
  };

  // Hàm tính chu vi và diện tích hình thoi
  const calculateRhombus = () => {
    const a = parseFloat(side);
    const d1 = parseFloat(diagonal1);
    const d2 = parseFloat(diagonal2);

    // Tính chu vi P = a x 4
    const P = a * 4;
    setPerimeter(P.toFixed(decimalPlaces));

    // Tính diện tích S = 1/2 (d1 x d2)
    const S = 0.5 * d1 * d2;
    setArea(S.toFixed(decimalPlaces));
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setSide('');
    setHeight('');
    setDiagonal1('');
    setDiagonal2('');
    setAngleAlpha('');
    setAngleBeta('');
    setPerimeter(null);
    setArea(null);
    setAngleUnitAlpha('rad');
    setAngleUnitBeta('rad');
  };

  return (
    <div class="content">
        <h1>Diện Tích và Chu Vi Hình Thoi</h1>
        
        <h2>Cách Tính Chu Vi</h2>
        <p>Chu vi của hình thoi được tính bằng độ dài một cạnh nhân với 4. Số 4 ở đây được hiểu là 4 cạnh của hình thoi.</p>
        
        
        <p>Diện tích của hình thoi được tính bằng nửa tích (1/2) độ dài của hai đường chéo.</p>
        
        <h2>Các Công Thức</h2>
        <img src="/image/DienTichChuVi/dien-tich-va-chu-vi-hinh-thoi-300x264.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/DienTichChuVi/cong-thuc-dien-tich-va-chu-vi-hinh-thoi-300x226.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />

        <h2>Cách Tính Diện Tích</h2>

        <h2>Định Nghĩa Hình Thoi</h2>
        <p>
            <ul>
                <li>Hình thoi là một tứ giác mà các cạnh đều cùng độ dài, nhưng không tạo thành góc vuông.</li>
                <li>Các đường chéo không có độ dài bằng nhau, nhưng tạo thành góc vuông và cắt nhau ở điểm giữa.</li>
                <li>Đường cao là khoảng cách vuông góc giữa hai cạnh đối diện.</li>
            </ul>
        </p>

        <h2>Các Thành Phần</h2>
        <p>
            <ul>
                <li><strong>P</strong> – chu vi</li>
                <li><strong>S</strong> – diện tích</li>
                <li><strong>a</strong> – chiều dài các cạnh</li>
                <li><strong>h</strong> – đường cao</li>
                <li><strong>d<sub>1</sub>, d<sub>2</sub></strong> – độ dài các đường chéo</li>
                <li><strong>α<sub>1</sub>, α<sub>2</sub></strong> – các góc</li>
            </ul>
        </p>

        <p class="note">Lưu ý: Để tính toán chính xác, bạn cần biết độ dài các cạnh và đường chéo của hình thoi.</p>


    <div className="container">
      <h2>Máy tính diện tích hình thoi Online</h2>
      <p>Hãy đưa ra 2 giá trị</p>

      <div className="input-container">
        <span>a =</span>
        <input
          type="number"
          value={side}
          onChange={(e) => setSide(e.target.value)}
        />
      </div>

      <div className="input-container">
        <span>h =</span>
        <input
          type="number"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
        />
      </div>

      <div className="input-container">
        <span>d1 =</span>
        <input
          type="number"
          value={diagonal1}
          onChange={(e) => setDiagonal1(e.target.value)}
        />
      </div>

      <div className="input-container">
        <span>d2 =</span>
        <input
          type="number"
          value={diagonal2}
          onChange={(e) => setDiagonal2(e.target.value)}
        />
      </div>

      <div className="input-container">
        <span>α1 =</span>
        <input
          type="number"
          value={angleAlpha}
          onChange={(e) => setAngleAlpha(e.target.value)}
        />
        <select
          value={angleUnitAlpha}
          onChange={(e) => setAngleUnitAlpha(e.target.value)}
        >
          <option value="rad">rad</option>
          <option value="deg">°</option>
        </select>
      </div>

      <div className="input-container">
        <span>α2 =</span>
        <input
          type="number"
          value={angleBeta}
          onChange={(e) => setAngleBeta(e.target.value)}
        />
        <select
          value={angleUnitBeta}
          onChange={(e) => setAngleUnitBeta(e.target.value)}
        >
          <option value="rad">rad</option>
          <option value="deg">°</option>
        </select>
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateRhombus}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {perimeter && (
        <div className="result">
          <p>Chu vi P = {perimeter}</p>
          <p>Diện tích S = {area}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default RhombusCalculator;
