import React, { useState } from 'react';
import '../../components/styles.css';

const PowerConverter = () => {
  const [powerValue, setPowerValue] = useState('');
  const [fromUnit, setFromUnit] = useState('W');
  const [toUnit, setToUnit] = useState('W');
  const [result, setResult] = useState(null);

  const convertPower = () => {
    const value = parseFloat(powerValue);
    let convertedValue;

    // Chuyển đổi giữa các đơn vị
    if (fromUnit === 'W') {
      if (toUnit === 'W') {
        convertedValue = value; // Watt sang Watt
      } else if (toUnit === 'kW') {
        convertedValue = value / 1000; // Watt sang kilowatt
      } else if (toUnit === 'MW') {
        convertedValue = value / 1_000_000; // Watt sang megawatt
      } else if (toUnit === 'HP') {
        convertedValue = value * 0.00134102; // Watt sang mã lực
      }
    } else if (fromUnit === 'kW') {
      if (toUnit === 'W') {
        convertedValue = value * 1000; // Kilowatt sang Watt
      } else if (toUnit === 'kW') {
        convertedValue = value; // Kilowatt sang kilowatt
      } else if (toUnit === 'MW') {
        convertedValue = value / 1000; // Kilowatt sang megawatt
      } else if (toUnit === 'HP') {
        convertedValue = value * 1.34102; // Kilowatt sang mã lực
      }
    } else if (fromUnit === 'MW') {
      if (toUnit === 'W') {
        convertedValue = value * 1_000_000; // Megawatt sang Watt
      } else if (toUnit === 'kW') {
        convertedValue = value * 1000; // Megawatt sang kilowatt
      } else if (toUnit === 'MW') {
        convertedValue = value; // Megawatt sang megawatt
      } else if (toUnit === 'HP') {
        convertedValue = value * 1_341_000; // Megawatt sang mã lực
      }
    } else if (fromUnit === 'HP') {
      if (toUnit === 'W') {
        convertedValue = value / 0.00134102; // Mã lực sang Watt
      } else if (toUnit === 'kW') {
        convertedValue = value / 1.34102; // Mã lực sang kilowatt
      } else if (toUnit === 'MW') {
        convertedValue = value / 1_341_000; // Mã lực sang megawatt
      } else if (toUnit === 'HP') {
        convertedValue = value; // Mã lực sang mã lực
      }
    }

    if (convertedValue !== undefined) {
      setResult(convertedValue);
    }
  };

  const clearFields = () => {
    setPowerValue('');
    setFromUnit('W');
    setToUnit('W');
    setResult(null);
  };

  return (
    <div className="container">
      <h2>Máy tính chuyển đổi công suất</h2>
      <p>Hãy đưa ra giá trị công suất và chọn đơn vị</p>
      <input
        type="number"
        value={powerValue}
        onChange={(e) => setPowerValue(e.target.value)}
        placeholder="Nhập giá trị"
      />
      <select value={fromUnit} onChange={(e) => setFromUnit(e.target.value)}>
        <option value="W">watt (W)</option>
        <option value="kW">kilowatt (kW)</option>
        <option value="MW">mêgawatt (MW)</option>
        <option value="HP">mã lực (HP)</option>
      </select>
      <span>đến</span>
      <select value={toUnit} onChange={(e) => setToUnit(e.target.value)}>
        <option value="W">watt (W)</option>
        <option value="kW">kilowatt (kW)</option>
        <option value="MW">mêgawatt (MW)</option>
        <option value="HP">mã lực (HP)</option>
      </select>

      <button onClick={convertPower}>Chuyển đổi</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Kết quả: {result.toFixed(6)} {toUnit}</p>
        </div>
      )}
    </div>
  );
};

export default PowerConverter;
