import React, { useState } from 'react';
import '../../components/styles.css'; 

const TanCalculator = () => {
  const [angle, setAngle] = useState('');
  const [tanValue, setTanValue] = useState(null);
  const [roundedTan, setRoundedTan] = useState(6);
  const [alphaValue, setAlphaValue] = useState('');
  const [angleUnit, setAngleUnit] = useState('deg'); 

  // Hàm tính giá trị tan
  const calculateTan = () => {
    let radians = angleUnit === 'deg' ? (angle * Math.PI) / 180 : angle;
    const tanResult = Math.tan(radians);
    setTanValue(tanResult.toFixed(roundedTan));
  };

  // Hàm tính giá trị ngược lại (tan^-1)
  const calculateInverseTan = () => {
    const value = parseFloat(alphaValue);
    if (isNaN(value)) {
      setAlphaValue('Giá trị không hợp lệ');
    } else {
      const angleInRad = Math.atan(value); // Tính arctan
      const angleInDeg = (angleInRad * 180) / Math.PI; // Chuyển đổi sang độ
      setAlphaValue(angleInDeg.toFixed(4) + '°');
    }
  };

  return (
    <div className="container">
      <h2>Máy Tính Giải Tang Online</h2>

      <h3>Hãy viết góc α</h3>
      <div className="input-container">
        <span>α =</span>
        <input
          type="number"
          value={angle}
          onChange={(e) => setAngle(e.target.value)}
        />
        <select value={angleUnit} onChange={(e) => setAngleUnit(e.target.value)}>
          <option value="deg">°</option>
          <option value="rad">rad</option>
        </select>
      </div>
      
      <div className="rounded-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={roundedTan}
          onChange={(e) => setRoundedTan(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateTan}>Tính tan α</button>
      
      {tanValue !== null && (
        <div className="result">
          <p>tan α = {tanValue}</p>
        </div>
      )}

      <h3>Hãy cho giá trị tan α và chọn đơn vị góc</h3>
      <div className="input-container">
        <span>tan α =</span>
        <input
          type="number"
          value={alphaValue}
          onChange={(e) => setAlphaValue(e.target.value)}
        />
      </div>
      <button onClick={calculateInverseTan}>Tính α từ tan α</button>
      
      {alphaValue && (
        <div className="result">
          <p>α = {alphaValue}</p>
        </div>
      )}
    </div>
  );
};

export default TanCalculator;
