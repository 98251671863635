// Prism.js
import React, { useState } from 'react';
import '../../components/styles.css';

const Prism = () => {
  const [baseArea, setBaseArea] = useState('');
  const [height, setHeight] = useState('');
  const [volume, setVolume] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const calculatePrism = () => {
    const base = parseFloat(baseArea);
    const h = parseFloat(height);
    if (base > 0 && h > 0) {
      setVolume((base * h).toFixed(decimalPlaces));
    } else {
      setVolume('Giá trị không hợp lệ');
    }
  };

  const clearFields = () => {
    setBaseArea('');
    setHeight('');
    setVolume(null);
  };

  return (
    <div className='content'>
    <h1>Thể Tích và Diện Tích Hình Lăng Trụ</h1>
    <p>Diện tích xung quanh hình lăng trụ đứng bằng tổng diện tích các mặt bên hoặc bằng chu vi đáy nhân với chiều cao.</p>
    <p><strong>S<sub>xq</sub> = 2p.h</strong></p>
    <p>Trong đó:</p>
    <ul>
        <li><strong>p</strong> là nửa chu vi đáy</li>
        <li><strong>h</strong> là chiều cao</li>
    </ul>
    
    <p>Diện tích toàn phần của hình lăng trụ bằng tổng các diện tích xung quanh và diện tích hai đáy.</p>
    <p>Thể tích hình lăng trụ đứng bằng diện tích đáy nhân với chiều cao:</p>
    <p><strong>V = S . h</strong></p>
    <p>Trong đó:</p>
    <ul>
        <li><strong>S</strong> là diện tích đáy</li>
        <li><strong>h</strong> là chiều cao</li>
    </ul>

    <h2>Khái Niệm Hình Lăng Trụ</h2>
    <p>Lăng trụ được tạo bởi hai mặt đáy song song và vỏ mặt bên.</p>
    <p>Bảng tính thực hiện phép tính trong hình lăng trụ vuông đều. Lăng trụ vuông có các mặt bên vuông góc với mặt đáy.</p>
    <p>Lăng trụ đều là lăng trụ mà mặt đáy của nó có các cạnh dài bằng nhau.</p>

    <h2>Các Công Thức</h2>
    <img src="/image/TheTich-DienTich/the-tich-va-dien-tich-hinh-cau.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/TheTich-DienTich/cong-thuc-the-tich-va-dien-tich-hinh-cau-224x300.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
    <ul>
        <li><strong>V</strong> – thể tích</li>
        <li><strong>A</strong> – diện tích</li>
        <li><strong>A<sub>d</sub></strong> – diện tích đáy</li>
        <li><strong>A<sub>xq</sub></strong> – diện tích xung quanh</li>
    </ul>

    <div className="container">
      <h2>Máy tính thể tích hình lăng trụ Online</h2>
      <div className="input-container">
        <span>Diện tích đáy =</span>
        <input type="number" value={baseArea} onChange={(e) => setBaseArea(e.target.value)} />
      </div>
      <div className="input-container">
        <span>Chiều cao =</span>
        <input type="number" value={height} onChange={(e) => setHeight(e.target.value)} />
      </div>
      <div className="decimal-container">
        <label>Làm tròn</label>
        <input type="number" value={decimalPlaces} onChange={(e) => setDecimalPlaces(e.target.value)} />
        <span>số thập phân</span>
      </div>
      <button onClick={calculatePrism}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>
      {volume !== null && <div className="result">Thể tích V = {volume}</div>}
    </div>
    </div>
  );
};

export default Prism;
