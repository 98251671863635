import React, { useState } from 'react';
import '../../components/styles.css'; 

const Parallelogram = () => {
  const [a, setA] = useState('');
  const [b, setB] = useState('');
  const [ha, setHa] = useState('');
  const [hb, setHb] = useState('');
  const [d1, setD1] = useState('');
  const [d2, setD2] = useState('');
  const [alpha1, setAlpha1] = useState('');
  const [alpha2, setAlpha2] = useState('');
  const [area, setArea] = useState(null);
  const [perimeter, setPerimeter] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  // Hàm tính chu vi và diện tích hình bình hành
  const calculateParallelogram = () => {
    const sideA = parseFloat(a);
    const sideB = parseFloat(b);
    const heightA = parseFloat(ha);

    // Tính chu vi và diện tích
    const P = 2 * (sideA + sideB); // Chu vi
    const S = sideA * heightA; // Diện tích

    setPerimeter(P.toFixed(decimalPlaces));
    setArea(S.toFixed(decimalPlaces));
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setA('');
    setB('');
    setHa('');
    setHb('');
    setD1('');
    setD2('');
    setAlpha1('');
    setAlpha2('');
    setPerimeter(null);
    setArea(null);
  };

  return (
    <div class="content">
        <h1>Diện Tích và Chu Vi Hình Bình Hành</h1>
        
        <h2>Cách Tính Chu Vi</h2>
        <p>Chu vi hình bình hành bằng giá trị gấp hai lần tổng chiều dài cộng chiều rộng.</p>
        

        <h2>Cách Tính Diện Tích</h2>
        <p>Diện tích hình bình hành bằng tích của chiều dài nhân với chiều cao.</p>
        
        <h2>Các Công Thức</h2>
        
        <img src="/image/DienTichChuVi/dien-tich-va-chu-vi-cua-hinh-binh-hanh-300x236.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/DienTichChuVi/cong-thuc-dien-tich-va-chu-vi-cua-hinh-binh-hanh-300x180.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <h2>Định Nghĩa Hình Bình Hành</h2>
        <p>
            <ul>
                <li>Hình bình hành là tứ giác có cạnh đối diện song song và bằng nhau, các cạnh liền kề không tạo thành góc vuông.</li>
                <li>Các đường chéo không dài bằng nhau và không vuông góc, nhưng cắt nhau ở điểm giữa.</li>
                <li>Đường cao là khoảng cách vuông góc giữa các cạnh đối diện.</li>
            </ul>
        </p>

        <h2>Các Thành Phần</h2>
        <p>
            <ul>
                <li><strong>P</strong> – chu vi</li>
                <li><strong>S</strong> – diện tích</li>
                <li><strong>a, b</strong> – chiều dài các cạnh</li>
                <li><strong>d<sub>1</sub>, d<sub>2</sub></strong> – độ dài các đường chéo</li>
                <li><strong>h<sub>a</sub></strong> – chiều cao trên cạnh <strong>a</strong></li>
                <li><strong>h<sub>b</sub></strong> – chiều cao trên cạnh <strong>b</strong></li>
            </ul>
        </p>

        <p class="note">Lưu ý: Để tính toán chính xác, bạn cần biết chiều dài các cạnh và chiều cao của hình bình hành.</p>


    <div className="container">
      <h2>Máy tính diện tích và chu vi hình bình hành Online</h2>
      <p>Hãy đưa ra 3 giá trị</p>

      <div className="input-container">
        <span>a =</span>
        <input
          type="number"
          value={a}
          onChange={(e) => setA(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>b =</span>
        <input
          type="number"
          value={b}
          onChange={(e) => setB(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>ha =</span>
        <input
          type="number"
          value={ha}
          onChange={(e) => setHa(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>hb =</span>
        <input
          type="number"
          value={hb}
          onChange={(e) => setHb(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>d1 =</span>
        <input
          type="number"
          value={d1}
          onChange={(e) => setD1(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>d2 =</span>
        <input
          type="number"
          value={d2}
          onChange={(e) => setD2(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>α1 =</span>
        <input
          type="number"
          value={alpha1}
          onChange={(e) => setAlpha1(e.target.value)}
        />
        <select>
          <option value="rad">rad</option>
          <option value="deg">°</option>
        </select>
      </div>
      <div className="input-container">
        <span>α2 =</span>
        <input
          type="number"
          value={alpha2}
          onChange={(e) => setAlpha2(e.target.value)}
        />
        <select>
          <option value="rad">rad</option>
          <option value="deg">°</option>
        </select>
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateParallelogram}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {perimeter !== null && area !== null && (
        <div className="result">
          <p>Chu vi P = {perimeter}</p>
          <p>Diện tích S = {area}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default Parallelogram;
