import React, { useState } from 'react';
import '../../components/styles.css'; 

const Polygon = () => {
  const [n, setN] = useState('');
  const [a, setA] = useState('');
  const [R, setR] = useState('');
  const [r, setRsmall] = useState('');
  const [perimeter, setPerimeter] = useState(null);
  const [area, setArea] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  // Hàm tính chu vi và diện tích của đa giác đều
  const calculatePolygon = () => {
    const sides = parseInt(n); // số cạnh
    const sideLength = parseFloat(a); // chiều dài cạnh
    const radiusR = parseFloat(R); // bán kính đường tròn ngoại tiếp
    const radiusr = parseFloat(r); // bán kính đường tròn nội tiếp

    // Tính chu vi
    const P = sides * sideLength; // Chu vi

    // Tính độ dài đường trung đoạn
    const midSegmentLength = sideLength / (2 * Math.tan(Math.PI / sides)); // Độ dài đường trung đoạn

    // Tính diện tích
    const S = (midSegmentLength * P) / 2; // Diện tích

    setArea(S.toFixed(decimalPlaces));
    setPerimeter(P.toFixed(decimalPlaces));
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setN('');
    setA('');
    setR('');
    setRsmall('');
    setPerimeter(null);
    setArea(null);
  };

  return (
    <div class="content">
        <h1>Diện Tích và Chu Vi Đa Giác Đều</h1>
        
        <h2>Định Nghĩa Đa Giác Đều</h2>
        <p>Đa giác đều là một hình học hai chiều có tất cả các cạnh bằng nhau và các góc ở đỉnh bằng nhau.</p>

        <h2>Cách Tính Chu Vi</h2>
        <p>Chu vi của đa giác đều là tổng chiều dài các mặt ngoài của hình học phẳng. Với một đa giác đều, chu vi có thể được tính bằng cách nhân chiều dài một cạnh với số cạnh <em>(n)</em>.</p>
        
        <div class="formula">
            <strong>Công thức tính chu vi đa giác đều:</strong> 
            <code>P = n × a</code>
        </div>

        <h2>Xác Định Đường Trung Đoạn</h2>
        <p>Đường trung đoạn của một đa giác đều là đoạn thẳng vuông góc hạ từ tâm xuống một cạnh của nó. Đường trung đoạn hơi khó tính hơn một chút so với chu vi.</p>

        <h2>Cách Tính Diện Tích</h2>
        <p>Diện tích của bất kỳ đa giác đều nào cũng được tính bằng công thức:</p>
        
        <h2>Các Công Thức</h2>
        <img src="/image/DienTichChuVi/dien-tich-chu-vi-da-giac-deu-300x300.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/DienTichChuVi/cong-thuc-tinh-dien-tich-chu-vi-da-giac-deu-204x300.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />

        <h2>Các Thành Phần</h2>
        <p>
            <ul>
                <li><strong>P</strong> – chu vi</li>
                <li><strong>S</strong> – diện tích</li>
                <li><strong>a</strong> – độ dài các cạnh</li>
                <li><strong>R</strong> – bán kính đường tròn ngoại tiếp</li>
                <li><strong>r</strong> – bán kính đường tròn nội tiếp</li>
                <li><strong>n</strong> – số cạnh</li>
                <li><strong>S′</strong> – tâm đa giác</li>
                <li><strong>K</strong> – đường tròn ngoại tiếp</li>
                <li><strong>k</strong> – đường tròn nội tiếp</li>
            </ul>
        </p>

        <p class="note">Lưu ý: Để tính toán chính xác, bạn cần biết độ dài các cạnh, số cạnh và các bán kính của các đường tròn.</p>

    <div className="container">
      <h2>Máy tính diện tích hình đa giác Online</h2>
      <p>Hãy viết số cạnh (số góc)</p>

      <div className="input-container">
        <span>n =</span>
        <input
          type="number"
          value={n}
          onChange={(e) => setN(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>a =</span>
        <input
          type="number"
          value={a}
          onChange={(e) => setA(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>R =</span>
        <input
          type="number"
          value={R}
          onChange={(e) => setR(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>r =</span>
        <input
          type="number"
          value={r}
          onChange={(e) => setRsmall(e.target.value)}
        />
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculatePolygon}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {perimeter !== null && area !== null && (
        <div className="result">
          <p>Chu vi P = {perimeter}</p>
          <p>Diện tích S = {area}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default Polygon;
