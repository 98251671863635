import React, { useState } from 'react';
import '../../components/styles.css';

const AreaConverter = () => {
  const [areaValue, setAreaValue] = useState('');
  const [fromUnit, setFromUnit] = useState('m2');
  const [toUnit, setToUnit] = useState('m2');
  const [result, setResult] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const convertArea = () => {
    const value = parseFloat(areaValue);
    let convertedValue;

    // Chuyển đổi giữa các đơn vị
    const conversionRates = {
      'mm2': 1e-6,
      'cm2': 1e-4,
      'dm2': 0.01,
      'm2': 1,
      'a': 0.01,
      'ha': 0.0001,
      'km2': 1e6,
      'sq in': 0.00064516,
      'sq ft': 0.092903,
      'sq yd': 0.836127,
      'acre': 4046.86,
      'sq mile': 2.58999e6,
    };

    if (fromUnit && toUnit) {
      convertedValue = (value * conversionRates[fromUnit]) / conversionRates[toUnit];
      setResult(convertedValue.toFixed(decimalPlaces));
    }
  };

  const clearFields = () => {
    setAreaValue('');
    setFromUnit('m2');
    setToUnit('m2');
    setResult(null);
    setDecimalPlaces(2);
  };

  return (
    <div className="container">
      <h2>Máy tính chuyển đổi diện tích</h2>
      <p>Hãy đưa ra diện tích và chọn đơn vị</p>
      <input
        type="number"
        value={areaValue}
        onChange={(e) => setAreaValue(e.target.value)}
        placeholder="Nhập giá trị"
      />
      <select value={fromUnit} onChange={(e) => setFromUnit(e.target.value)}>
        <option value="mm2">milimét vuông</option>
        <option value="cm2">xentimét vuông</option>
        <option value="dm2">đêximét vuông</option>
        <option value="m2">mét vuông</option>
        <option value="a">A</option>
        <option value="ha">hecta</option>
        <option value="km2">kilômét vuông</option>
        <option value="sq in">inch vuông</option>
        <option value="sq ft">foot vuông</option>
        <option value="sq yd">yard vuông</option>
        <option value="acre">mẫu</option>
        <option value="sq mile">dặm vuông</option>
      </select>
      <span>đến</span>
      <select value={toUnit} onChange={(e) => setToUnit(e.target.value)}>
        <option value="mm2">milimét vuông</option>
        <option value="cm2">xentimét vuông</option>
        <option value="dm2">đêximét vuông</option>
        <option value="m2">mét vuông</option>
        <option value="a">A</option>
        <option value="ha">hecta</option>
        <option value="km2">kilômét vuông</option>
        <option value="sq in">inch vuông</option>
        <option value="sq ft">foot vuông</option>
        <option value="sq yd">yard vuông</option>
        <option value="acre">mẫu</option>
        <option value="sq mile">dặm vuông</option>
      </select>

      <button onClick={convertArea}>Chuyển đổi</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Kết quả: {result}</p>
        </div>
      )}

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
          min="0"
        />
        <span>số thập phân</span>
      </div>
    </div>
  );
};

export default AreaConverter;
