import React, { useState } from 'react';
import '../Pages/styles/styles.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Xử lý gửi thông tin liên hệ ở đây
    console.log('Thông tin liên hệ:', formData);
    alert('Thông tin của bạn đã được gửi thành công!');
  };

  return (
    <div className="contact-container">
      <h1>Liên hệ với chúng tôi</h1>
      
      {/* Form liên hệ */}
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Tên của bạn</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email của bạn</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Lời nhắn</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="5"
            required
          ></textarea>
        </div>
        <button type="submit" className="submit-button">Gửi</button>
      </form>

      {/* Thông tin của chúng tôi */}
      <div className="company-info">
        <h2>Thông tin của chúng tôi</h2>
        <p><strong>Địa chỉ:</strong> 383 Nguyễn Văn Cừ, P Tân Lập, Thành phố Buôn Mê Thuột</p>
        <p><strong>Email:</strong> nam102154@donga.edu.vn</p>
        <p><strong>Điện thoại:</strong> (+84) 355114961</p>
        <p><strong>Thời gian làm việc:</strong> Thứ 2 - Thứ 6, 8:00 AM - 5:00 PM</p>
      </div>
    </div>
  );
};

export default Contact;
