import React, { useState } from 'react';
import '../../components/styles.css'; 

const SquareRoot = () => {
  const [x, setX] = useState('');
  const [result, setResult] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  // Hàm tính căn bậc hai
  const calculateSquareRoot = () => {
    const value = parseFloat(x);
    if (value < 0) {
      setResult('Giá trị không thể âm');
    } else {
      const sqrtValue = Math.sqrt(value);
      setResult(sqrtValue.toFixed(decimalPlaces));
    }
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setX('');
    setResult(null);
  };

  return (
    <div class="content">
        <h1>Căn Bậc Hai</h1>
        
        <h2>Định Nghĩa</h2>
        <p>Căn bậc hai của một số <em>a</em> là một số <em>x</em> sao cho <em>x<sup>2</sup> = a</em>, hay nói cách khác là số <em>x</em> mà bình phương lên thì bằng <em>a</em>. Ví dụ, <strong>3</strong> và <strong>−3</strong> là căn bậc hai của <strong>9</strong> vì <em>3<sup>2</sup> = (−3)<sup>2</sup> = 9</em>.</p>
        
        <h2>Căn Bậc Hai Chính</h2>
        <p>Mọi số thực <em>a</em> không âm đều có một căn bậc hai không âm duy nhất, gọi là căn bậc hai chính, ký hiệu <strong>√a</strong>, ở đây <strong>√</strong> được gọi là dấu căn.</p>

        <h2>Đồ Thị</h2>
        <img src="/image/LuyThua-KhaiCan/do-thi-khai-can-bac-2-300x240.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        
        <h2>Công Thức</h2>
        <img src="/image/LuyThua-KhaiCan/cong-thuc-khai-can-bac-2-300x193.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        
        <p class="note">Lưu ý: Hàm số căn bậc hai chỉ định nghĩa cho <em>x</em> không âm.</p>

    <div className="container">
      <h2>Máy tính căn bậc hai Online</h2>
      <p>Hãy đưa ra 1 giá trị</p>

      <div className="input-container">
        <span>x =</span>
        <input
          type="number"
          value={x}
          onChange={(e) => setX(e.target.value)}
        />
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateSquareRoot}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Căn bậc hai của {x} là: √{x} = {result}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default SquareRoot;
