import React, { useState } from 'react';
import '../../components/styles.css';

const Sphere = () => {
  const [radius, setRadius] = useState('');
  const [diameter, setDiameter] = useState('');
  const [volume, setVolume] = useState(null);
  const [surfaceArea, setSurfaceArea] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const calculateSphere = () => {
    const r = parseFloat(radius);
    const d = parseFloat(diameter);

    if (r > 0) {
      setVolume(((4 / 3) * Math.PI * Math.pow(r, 3)).toFixed(decimalPlaces));
      setSurfaceArea((4 * Math.PI * Math.pow(r, 2)).toFixed(decimalPlaces));
    } else if (d > 0) {
      const rFromDiameter = d / 2;
      setVolume(((4 / 3) * Math.PI * Math.pow(rFromDiameter, 3)).toFixed(decimalPlaces));
      setSurfaceArea((4 * Math.PI * Math.pow(rFromDiameter, 2)).toFixed(decimalPlaces));
    } else {
      setVolume('Giá trị không hợp lệ');
      setSurfaceArea('Giá trị không hợp lệ');
    }
  };

  // Hàm xóa dữ liệu
  const clearFields = () => {
    setRadius('');
    setDiameter('');
    setVolume(null);
    setSurfaceArea(null);
  };

  return (
    <div className='content'>
      <h1>Thể Tích và Diện Tích Hình Cầu</h1>
      <p>Công thức tính thể tích hình cầu, hướng dẫn cách tính diện tích mặt cầu, cách tính thể tích khối cầu.</p>

      <h2>Mặt cầu (O,R)</h2>
      <p>Mặt cầu (O,R) là mặt được tạo bởi quỹ tích các điểm cách điểm O một khoảng chiều dài không đổi bằng bán kính R trong không gian 3 chiều.</p>

      <h2>Khái Niệm Hình Cầu</h2>
      <p>Hình cầu được tạo bởi tâm và bán kính hoặc đường kính.</p>

      <h2>Các Công Thức</h2>
        <img src="/image/TheTich-DienTich/the-tich-va-dien-tich-hinh-cau.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/TheTich-DienTich/cong-thuc-the-tich-va-dien-tich-hinh-cau-224x300.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />

      <p>Công thức tính thể tích khối cầu: <strong>V = 4/3(πr<sup>3</sup>)</strong></p>
      <p>Diện tích mặt cầu: <strong>S = 4πR<sup>2</sup></strong></p>
      <p>Trong đó, R là bán kính khối cầu (mặt cầu, hình cầu).</p>

      <h2>Các Thành Phần</h2>
      <ul>
          <li><strong>V</strong> – thể tích</li>
          <li><strong>A</strong> – diện tích</li>
          <li><strong>d</strong> – đường kính</li>
          <li><strong>S'</strong> – tâm</li>
          <li><strong>d</strong> – đường kính</li>
      </ul>


    <div className="container">
      <h2>Máy tính diện tích và thể tích hình cầu Online</h2>
      <p>Hãy đưa ra 1 giá trị</p>

      <div className="input-container">
        <span>r =</span>
        <input
          type="number"
          value={radius}
          onChange={(e) => setRadius(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>d =</span>
        <input
          type="number"
          value={diameter}
          onChange={(e) => setDiameter(e.target.value)}
        />
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateSphere}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {volume !== null && (
        <div className="result">
          <p>Thể tích V = {volume}</p>
          <p>Diện tích A = {surfaceArea}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default Sphere;
