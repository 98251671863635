import React, { useState } from 'react';
import '../../components/styles.css'; 

const NthPower = () => {
  const [x, setX] = useState(''); // Giá trị x
  const [n, setN] = useState(''); // Giá trị n
  const [result, setResult] = useState(null); // Kết quả
  const [decimalPlaces, setDecimalPlaces] = useState(2); // Số thập phân làm tròn

  // Hàm tính lũy thừa bậc n
  const calculateNthPower = () => {
    const value = parseFloat(x);
    const degree = parseFloat(n);
    const nthPowerValue = Math.pow(value, degree);
    setResult(nthPowerValue.toFixed(decimalPlaces));
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setX('');
    setN('');
    setResult(null);
  };

  return (
    <div class="content">
        <h1>Lũy Thừa Bậc N</h1>
        
        <h2>Định Nghĩa</h2>
        <p>Lũy thừa bậc n của một số là phép toán nhân n thừa số là chính số đó với nhau. Hay lũy thừa bậc n của <em>a</em> là tích n thừa số bằng nhau nhân lại, mỗi thừa số bằng <em>a</em>.</p>
        
        <div class="formula">
            <strong>Công thức lũy thừa bậc n:</strong> 
            <code>a<sup>n</sup> = a × a × a × ... × a (n thừa số)</code> (với n ≠ 0)
        </div>

        <h2>Các Khái Niệm</h2>
        <p>
            <strong>a</strong> gọi là cơ số, <strong>n</strong> gọi là mũ số.
        </p>

        <h2>Đồ Thị</h2>
          <img src="/image/LuyThua-KhaiCan/luy-thua-mu-n-1.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <h2>Công Thức</h2>
          <img src="/image/LuyThua-KhaiCan/cong-thuc-luy-thua-mu-n-300x194.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />

    <div className="container">
      <h2>Máy tính lũy thừa bậc n Online</h2>
      <p>Hãy đưa ra 2 giá trị</p>

      <div className="input-container">
        <span>x =</span>
        <input
          type="number"
          value={x}
          onChange={(e) => setX(e.target.value)}
        />
      </div>
      
      <div className="input-container">
        <span>n =</span>
        <input
          type="number"
          value={n}
          onChange={(e) => setN(e.target.value)}
        />
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateNthPower}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>{x} lũy thừa bậc {n} là: {x}ⁿ = {result}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default NthPower;
