import React, { useState } from 'react';
import '../../components/styles.css'; 

const LinearSystem = () => {
  const [a, setA] = useState(''); // Hệ số a
  const [b, setB] = useState(''); // Hệ số b
  const [c, setC] = useState(''); // Hệ số c
  const [d, setD] = useState(''); // Hệ số d
  const [e, setE] = useState(''); // Hệ số e
  const [f, setF] = useState(''); // Hệ số f
  const [result, setResult] = useState(null); // Kết quả

  // Hàm tính nghiệm x và y của hệ phương trình
  const calculateSolution = () => {
    const coeffA = parseFloat(a);
    const coeffB = parseFloat(b);
    const coeffC = parseFloat(c);
    const coeffD = parseFloat(d);
    const coeffE = parseFloat(e);
    const coeffF = parseFloat(f);

    // Tính toán nghiệm x và y
    const denominator = coeffA * coeffE - coeffB * coeffD;
    if (denominator === 0) {
      setResult('Hệ phương trình vô nghiệm hoặc có vô số nghiệm');
      return;
    }

    const x = (coeffC * coeffE - coeffB * coeffF) / denominator;
    const y = (coeffA * coeffF - coeffC * coeffD) / denominator;

    setResult(`x = ${x.toFixed(2)}, y = ${y.toFixed(2)}`);
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setA('');
    setB('');
    setC('');
    setD('');
    setE('');
    setF('');
    setResult(null);
  };

  return (
    <div className="content">
      <h1>Hệ Hai Phương Trình Bậc Nhất Hai Ẩn</h1>

      <h2>Định Nghĩa</h2>
      <p>Hệ hai phương trình bậc nhất hai ẩn có dạng:</p>
      <p>(I) {'ax + by = c và a’x + b’y = c’'}</p>
      <p>Trong đó <strong>ax + by = c</strong> và <strong>a’x + b’y = c’</strong> là những phương trình bậc nhất hai ẩn. Nếu hai phương trình của hệ có nghiệm chung thì nghiệm chung ấy gọi là nghiệm của hệ phương trình (I). Trái lại, nếu hai phương trình không có nghiệm chung thì ta nói hệ (I) là vô nghiệm.</p>

      <h2>Máy Tính Online Giải Hệ Phương Trình Bậc Một Với Hai Ẩn Số</h2>
      <p>Hãy đưa ra phương trình theo dạng mẫu chung. Trường hợp trong phương trình có dấu âm, hãy viết biến số tương ứng là một số âm.</p>

      <h2>Đồ Thị</h2>
      <img src="/image/PhuongTrinh/he-phuong-trinh-1.jpg" alt="Đồ thị phương trình bậc nhất" style={{ maxWidth: '50%', height: 'auto' }} />
      <img src="/image/PhuongTrinh/he-phuong-trinh.jpg" alt="Đồ thị phương trình bậc nhất" style={{ maxWidth: '50%', height: 'auto' }} />
      
      <div className="container">
        <h2>Máy tính giải hệ phương trình Online</h2>
        <p>Hãy đưa ra biến số:</p>

        <div className="input-container">
          <span>a =</span>
          <input
            type="number"
            value={a}
            onChange={(e) => setA(e.target.value)}
          />
        </div>

        <div className="input-container">
          <span>b =</span>
          <input
            type="number"
            value={b}
            onChange={(e) => setB(e.target.value)}
          />
        </div>

        <div className="input-container">
          <span>c =</span>
          <input
            type="number"
            value={c}
            onChange={(e) => setC(e.target.value)}
          />
        </div>

        <div className="input-container">
          <span>d =</span>
          <input
            type="number"
            value={d}
            onChange={(e) => setD(e.target.value)}
          />
        </div>

        <div className="input-container">
          <span>e =</span>
          <input
            type="number"
            value={e}
            onChange={(e) => setE(e.target.value)}
          />
        </div>

        <div className="input-container">
          <span>f =</span>
          <input
            type="number"
            value={f}
            onChange={(e) => setF(e.target.value)}
          />
        </div>

        <button onClick={calculateSolution}>Tính</button>
        <button className="clear-button" onClick={clearFields}>Xóa</button>

        {result !== null && (
          <div className="result">
            <p>Phương trình: {a}x + {b}y = {c}</p>
            <p>{d}x + {e}y = {f}</p>
            <p>Kết quả: {result}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LinearSystem;
