import React, { useState } from 'react';
import '../../components/styles.css';

const TimeConverter = () => {
  const [timeValue, setTimeValue] = useState({ seconds: 0, minutes: 0, hours: 0, days: 0, weeks: 0, months: 0, years: 0 });
  const [result, setResult] = useState(null);
  const [fromUnit, setFromUnit] = useState('s');
  const [toUnit, setToUnit] = useState('s');

  const convertTime = () => {
    // Tính tổng giây từ giá trị đầu vào
    const totalSeconds =
      timeValue.seconds +
      timeValue.minutes * 60 +
      timeValue.hours * 3600 +
      timeValue.days * 86400 +
      timeValue.weeks * 604800 +
      timeValue.months * 2628000 + // Trung bình 30.44 ngày mỗi tháng
      timeValue.years * 31536000; // 365 ngày

    // Chuyển đổi từ giây sang đơn vị đích
    let convertedValue;
    switch (toUnit) {
      case 's':
        convertedValue = totalSeconds;
        break;
      case 'min':
        convertedValue = totalSeconds / 60;
        break;
      case 'h':
        convertedValue = totalSeconds / 3600;
        break;
      case 'days':
        convertedValue = totalSeconds / 86400;
        break;
      case 'weeks':
        convertedValue = totalSeconds / 604800;
        break;
      case 'months':
        convertedValue = totalSeconds / 2628000; // Trung bình 30.44 ngày mỗi tháng
        break;
      case 'years':
        convertedValue = totalSeconds / 31536000; // 365 ngày
        break;
      default:
        convertedValue = totalSeconds;
    }

    if (convertedValue !== undefined) {
      setResult(convertedValue);
    }
  };

  const clearFields = () => {
    setTimeValue({ seconds: 0, minutes: 0, hours: 0, days: 0, weeks: 0, months: 0, years: 0 });
    setFromUnit('s');
    setToUnit('s');
    setResult(null);
  };

  return (
    <div className="container">
      <h2>Máy tính chuyển đổi thời gian</h2>
      <p>Hãy đưa ra giá trị thời gian và chọn đơn vị</p>
      <div>
        <input
          type="number"
          value={timeValue.seconds}
          onChange={(e) => setTimeValue({ ...timeValue, seconds: e.target.value })}
          placeholder="Giây"
        /> giây
      </div>
      <div>
        <input
          type="number"
          value={timeValue.minutes}
          onChange={(e) => setTimeValue({ ...timeValue, minutes: e.target.value })}
          placeholder="Phút"
        /> phút
      </div>
      <div>
        <input
          type="number"
          value={timeValue.hours}
          onChange={(e) => setTimeValue({ ...timeValue, hours: e.target.value })}
          placeholder="Giờ"
        /> giờ
      </div>
      <div>
        <input
          type="number"
          value={timeValue.days}
          onChange={(e) => setTimeValue({ ...timeValue, days: e.target.value })}
          placeholder="Ngày"
        /> ngày
      </div>
      <div>
        <input
          type="number"
          value={timeValue.weeks}
          onChange={(e) => setTimeValue({ ...timeValue, weeks: e.target.value })}
          placeholder="Tuần"
        /> tuần
      </div>
      <div>
        <input
          type="number"
          value={timeValue.months}
          onChange={(e) => setTimeValue({ ...timeValue, months: e.target.value })}
          placeholder="Tháng"
        /> tháng
      </div>
      <div>
        <input
          type="number"
          value={timeValue.years}
          onChange={(e) => setTimeValue({ ...timeValue, years: e.target.value })}
          placeholder="Năm"
        /> năm
      </div>

      <span>Đến</span>
      <select value={toUnit} onChange={(e) => setToUnit(e.target.value)}>
        <option value="s">giây (s)</option>
        <option value="min">phút (min)</option>
        <option value="h">giờ (h)</option>
        <option value="days">ngày</option>
        <option value="weeks">tuần</option>
        <option value="months">tháng</option>
        <option value="years">năm</option>
      </select>

      <button onClick={convertTime}>Chuyển đổi</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Kết quả: {result.toFixed(2)} {toUnit}</p>
        </div>
      )}
    </div>
  );
};

export default TimeConverter;
