// RectangularBox.js
import React, { useState } from 'react';
import '../../components/styles.css';

const RectangularBox = () => {
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [volume, setVolume] = useState(null);
  const [surfaceArea, setSurfaceArea] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const calculateBox = () => {
    const l = parseFloat(length);
    const w = parseFloat(width);
    const h = parseFloat(height);
    if (l > 0 && w > 0 && h > 0) {
      setVolume((l * w * h).toFixed(decimalPlaces));
      setSurfaceArea((2 * (l * w + l * h + w * h)).toFixed(decimalPlaces));
    } else {
      setVolume('Giá trị không hợp lệ');
      setSurfaceArea('Giá trị không hợp lệ');
    }
  };

  const clearFields = () => {
    setLength('');
    setWidth('');
    setHeight('');
    setVolume(null);
    setSurfaceArea(null);
  };

  return (
    <div className='content'>
    <h1>Thể Tích và Diện Tích Hình Hộp Chữ Nhật</h1>
    <p>Diện tích xung quanh hình hộp chữ nhật bằng tích của chu vi đáy và chiều cao.</p>
    <p>Diện tích toàn phần hình hộp chữ nhật bằng tổng diện tích xung quanh hình hộp chữ nhật và 2 mặt còn lại.</p>
    <p>Thể tích hình hộp chữ nhật bằng tích của diện tích đáy và chiều cao.</p>

    <h2>Khái Niệm Hình Hộp Chữ Nhật</h2>
    <p>Hình hộp chữ nhật là hình khối mà các mặt bên của nó là sáu hoặc bốn hình chữ nhật và hai hình vuông. Các mặt đối diện song song và bằng nhau. Đường chéo của hình hộp dài bằng nhau.</p>

    <h2>Các Công Thức</h2>
    <img src="/image/TheTich-DienTich/the-tich-va-dien-tich-hinh-cau.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/TheTich-DienTich/cong-thuc-the-tich-va-dien-tich-hinh-cau-224x300.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
    <ul>
        <li><strong>V</strong> – thể tích</li>
        <li><strong>A</strong> – diện tích</li>
        <li><strong>a, b, c</strong> – các cạnh</li>
        <li><strong>d</strong> – đường chéo hình khối</li>
    </ul>


    <div className="container">
      <h2>Máy tính thể tích và diện tích hình hộp chữ nhật Online</h2>
      <div className="input-container">
        <span>Chiều dài =</span>
        <input type="number" value={length} onChange={(e) => setLength(e.target.value)} />
      </div>
      <div className="input-container">
        <span>Chiều rộng =</span>
        <input type="number" value={width} onChange={(e) => setWidth(e.target.value)} />
      </div>
      <div className="input-container">
        <span>Chiều cao =</span>
        <input type="number" value={height} onChange={(e) => setHeight(e.target.value)} />
      </div>
      <div className="decimal-container">
        <label>Làm tròn</label>
        <input type="number" value={decimalPlaces} onChange={(e) => setDecimalPlaces(e.target.value)} />
        <span>số thập phân</span>
      </div>
      <button onClick={calculateBox}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>
      {volume !== null && <div className="result">Thể tích V = {volume}, Diện tích A = {surfaceArea}</div>}
    </div>
    </div>
  );
};

export default RectangularBox;
