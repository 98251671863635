import React, { useState } from 'react';
import '../../components/styles.css';

const VolumeConverter = () => {
  const [volumeValue, setVolumeValue] = useState('');
  const [fromUnit, setFromUnit] = useState('l');
  const [toUnit, setToUnit] = useState('m3');
  const [result, setResult] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const convertVolume = () => {
    const value = parseFloat(volumeValue);
    let convertedValue;

    // Chuyển đổi giữa các đơn vị
    const conversionRates = {
      'mm3': 1e-9,
      'cm3': 1e-6,
      'dm3': 0.001,
      'm3': 1,
      'ml': 1e-6,
      'cl': 1e-5,
      'dl': 1e-4,
      'l': 0.001,
      'hl': 0.1,
      'gi': 0.000284131,
      'pt': 0.568261,
      'qt': 1.13652,
      'gal': 4.54609, // Imperial gallon
      'bl': 158.987,
      'pk': 0.009094,
      'bsh': 0.0352391,
      'qr': 0.00625
    };

    if (fromUnit && toUnit) {
      convertedValue = (value * conversionRates[fromUnit]) / conversionRates[toUnit];
      setResult(convertedValue.toFixed(decimalPlaces));
    }
  };

  const clearFields = () => {
    setVolumeValue('');
    setFromUnit('l');
    setToUnit('m3');
    setResult(null);
    setDecimalPlaces(2);
  };

  return (
    <div className="container">
      <h2>Máy tính chuyển đổi thể tích</h2>
      <p>Hãy đưa ra thể tích và chọn đơn vị</p>
      <input
        type="number"
        value={volumeValue}
        onChange={(e) => setVolumeValue(e.target.value)}
        placeholder="Nhập giá trị"
      />
      <select value={fromUnit} onChange={(e) => setFromUnit(e.target.value)}>
        <option value="mm3">milimét khối</option>
        <option value="cm3">xentimét khối</option>
        <option value="dm3">đêximét khối</option>
        <option value="m3">mét khối</option>
        <option value="ml">mililít</option>
        <option value="cl">xentilít</option>
        <option value="dl">đêxilít</option>
        <option value="l">lít</option>
        <option value="hl">hectôlít</option>
        <option value="gi">gill</option>
        <option value="pt">pint</option>
        <option value="qt">quart</option>
        <option value="gal">gallon</option>
        <option value="bl">barrel</option>
        <option value="pk">peck</option>
        <option value="bsh">bushel</option>
        <option value="qr">quarter</option>
      </select>
      <span>đến</span>
      <select value={toUnit} onChange={(e) => setToUnit(e.target.value)}>
        <option value="mm3">milimét khối</option>
        <option value="cm3">xentimét khối</option>
        <option value="dm3">đêximét khối</option>
        <option value="m3">mét khối</option>
        <option value="ml">mililít</option>
        <option value="cl">xentilít</option>
        <option value="dl">đêxilít</option>
        <option value="l">lít</option>
        <option value="hl">hectôlít</option>
        <option value="gi">gill</option>
        <option value="pt">pint</option>
        <option value="qt">quart</option>
        <option value="gal">gallon</option>
        <option value="bl">barrel</option>
        <option value="pk">peck</option>
        <option value="bsh">bushel</option>
        <option value="qr">quarter</option>
      </select>

      <button onClick={convertVolume}>Chuyển đổi</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Kết quả: {result}</p>
        </div>
      )}

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
          min="0"
        />
        <span>số thập phân</span>
      </div>
    </div>
  );
};

export default VolumeConverter;
