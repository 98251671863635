import React from 'react';
import '../Pages/styles/styles.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p>Nguyễn Bá Hoài Nam <br/>
      Class: IT23M</p>
    </footer>
  );
};

export default Footer;
