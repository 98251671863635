import React, { useState } from 'react';
import '../../components/styles.css'; 

const RightTriang = () => {
  const [sideA, setSideA] = useState('');
  const [sideB, setSideB] = useState('');
  const [sideC, setSideC] = useState('');
  const [angleAlpha, setAngleAlpha] = useState('');
  const [angleBeta, setAngleBeta] = useState('');
  const [decimalPlaces, setDecimalPlaces] = useState(2);
  const [perimeter, setPerimeter] = useState(null);
  const [area, setArea] = useState(null);

  // Hàm tính chu vi và diện tích tam giác vuông
  const calculateRightTriangle = () => {
    const a = parseFloat(sideA);
    const b = parseFloat(sideB);

    // Tính cạnh huyền theo định lý Pytagore nếu không nhập cạnh c
    const c = sideC ? parseFloat(sideC) : Math.sqrt(a * a + b * b);

    // Tính chu vi P = a + b + c
    const P = a + b + c;
    setPerimeter(P.toFixed(decimalPlaces));

    // Tính diện tích S = (ab)/2
    const S = (a * b) / 2;
    setArea(S.toFixed(decimalPlaces));
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setSideA('');
    setSideB('');
    setSideC('');
    setAngleAlpha('');
    setAngleBeta('');
    setPerimeter(null);
    setArea(null);
  };

  return (
    <div class="content">
        <h1>Diện Tích và Chu Vi Tam Giác Vuông</h1>
        
        <h2>Diện Tích Tam Giác Vuông</h2>
        <p>Trong một tam giác vuông, nếu một cạnh bên được coi là đáy thì cạnh bên còn lại được xem là chiều cao. Diện tích của tam giác vuông khi đó sẽ bằng một nửa tích của hai cạnh bên.</p>
        

        <h2>Chu Vi Tam Giác Vuông</h2>
        <p>Chu vi tam giác vuông chính là tổng độ dài của các đường bao quanh hình tam giác vuông đó.</p>
        
        <h2>Các Công Thức</h2>
        <img src="/image/DienTichChuVi/dien-tich-va-chu-vi-cua-tam-giac-vuong-300x240.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/DienTichChuVi/cong-thuc-dien-tich-va-chu-vi-cua-tam-giac-vuong-300x240.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />

        <h2>Thế Nào Là Tam Giác Vuông?</h2>
        <p>Tam giác vuông được tạo thành bởi hai cạnh vuông góc và cạnh huyền – cạnh dài nhất. Tổng các góc của một tam giác là 180°, suy ra: α + β = 90°. Độ dài của các cạnh có thể được tính bằng định lý Pythagore, độ lớn của góc được tính bằng hàm lượng giác.</p>

        <h2>Các Thành Phần</h2>
        <p>
            <ul>
                <li><strong>P</strong> – chu vi</li>
                <li><strong>S</strong> – diện tích</li>
                <li><strong>a, b</strong> – các cạnh bên tạo thành góc vuông</li>
                <li><strong>c</strong> – cạnh huyền</li>
            </ul>
        </p>

        <p class="note">Lưu ý: Để tính toán chính xác, bạn cần biết độ dài các cạnh của tam giác vuông.</p>


    <div className="container">
      <h2>Máy tính diện tích tam giác vuông Online</h2>
      <p>Hãy đưa ra 2 giá trị</p>

      <div className="input-container">
        <span>a =</span>
        <input
          type="number"
          value={sideA}
          onChange={(e) => setSideA(e.target.value)}
        />
      </div>

      <div className="input-container">
        <span>b =</span>
        <input
          type="number"
          value={sideB}
          onChange={(e) => setSideB(e.target.value)}
        />
      </div>

      <div className="input-container">
        <span>c =</span>
        <input
          type="number"
          value={sideC}
          onChange={(e) => setSideC(e.target.value)}
          placeholder="Tự động tính nếu bỏ trống"
        />
      </div>

      <div className="input-container">
        <span>α =</span>
        <input
          type="number"
          value={angleAlpha}
          onChange={(e) => setAngleAlpha(e.target.value)}
        />
        <span>°</span>
      </div>

      <div className="input-container">
        <span>β =</span>
        <input
          type="number"
          value={angleBeta}
          onChange={(e) => setAngleBeta(e.target.value)}
        />
        <span>°</span>
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateRightTriangle}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {perimeter && (
        <div className="result">
          <p>Chu vi P = {perimeter}</p>
          <p>Diện tích S = {area}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default RightTriang;
