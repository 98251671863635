import React, { useState } from 'react';
import './styles/styles.css'

const PercentageCalculator = () => {
    const [percentage, setPercentage] = useState('');
    const [number, setNumber] = useState('');
    const [result, setResult] = useState(null);
    const [decimalPlaces, setDecimalPlaces] = useState(2);
  
    const calculatePercentage = () => {
      if (percentage !== '' && number !== '') {
        const calculatedResult = (percentage / 100) * number;
        setResult(calculatedResult.toFixed(decimalPlaces));
      }
    };
  
    const clearFields = () => {
      setPercentage('');
      setNumber('');
      setResult(null);
    };
  
    return (
      <div className="content">
        <h1>Tính phần trăm</h1>
        <p>Hướng dẫn công thức tính % của số, cách tính phần trăm % nhanh nhất chính xác
          <br/>
          Tính phần trăm có thể có rất nhiều trường hợp khác nhau và yêu cầu khác nhau có thể làm phân vân với các bạn. Trong đó thường gặp nhất là tính phần trăm % chênh lệch giữa 2 năm, % của 1 con số, tính % lãi suất ngân hàng, tính phần % tăng trưởng, phần trăm lợi nhuận, phần trăm hoàn thành…</p>

      <div className="container">
        <h2>Máy tính Online</h2>
        <p>X % của số Y là bao nhiêu?</p>
  
        <div className="input-container">
          <input
            type="number"
            value={percentage}
            onChange={(e) => setPercentage(e.target.value)}
            placeholder="% của"
          />
          <span>% của</span>
          <input
            type="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            placeholder="số Y"
          />
          <span>là</span>
        </div>

        <div className="decimal-container">
          <label>Làm tròn</label>
          <input
            type="number"
            value={decimalPlaces}
            onChange={(e) => setDecimalPlaces(e.target.value)}
            min="0"
          />
          <span>số thập phân</span>
        </div>
  
        <button onClick={calculatePercentage}>Tính phần trăm</button>
        <button className="clear-button" onClick={clearFields}>Xóa</button>
  
        {result !== null && (
          <div className="result">
            <p>{percentage} % của {number} là {result}</p>
          </div>
        )}
  
        
      </div>
      </div>
    );
  };
  
  export default PercentageCalculator;