import React, { useState } from 'react';
import './styles/Calculator.css'; 
import MyButton from '../Pages/styles/MyButton';
import { evaluate } from 'mathjs';

const Calculator = () => {
  const [display, setDisplay] = useState('');

  const handleClick = (value) => {
    setDisplay(display + value);
  };

  const clearDisplay = () => {
    setDisplay('');
  };

 

  const calculate = () => {
    try {
      setDisplay(evaluate(display).toString());
    } catch (error) {
      setDisplay('Error');
    }
  };


  return (
    <div className="content">
      <h1>Máy Tính Online</h1>
        <p><strong>Máy tính online</strong> là một công cụ đơn giản và thuận tiện, giúp bạn thực hiện các phép tính toán dễ dàng cho công việc, học tập hoặc cá nhân. Công cụ này có thể tính lãi suất cho vay của ngân hàng, tính toán chi phí cho các công trình, và nhiều tiện ích khác.</p>

        <h2>Các tính năng chính của máy tính online:</h2>
        <ul>
            <li>Thực hiện các phép tính đơn giản (cộng, trừ, nhân, chia).</li>
            <li>Tính lãi cho khoản vay và lãi suất cho vay của ngân hàng.</li>
            <li>Tính toán chi phí cho các công trình xây dựng và tiện ích khác.</li>
        </ul>

        <p>Bạn có thể nhập lệnh cho máy tính không chỉ bằng chuột mà còn có thể sử dụng **bàn phím** để thực hiện các phép tính nhanh chóng và tiện lợi.</p>

        <h2>Hướng dẫn sử dụng máy tính:</h2>
        <p>Để biết thêm thông tin chi tiết về cách sử dụng máy tính online, vui lòng xem hướng dẫn sử dụng bên dưới.</p>

        <p class="note">Lưu ý: Các phép tính chỉ mang tính tham khảo. Để có kết quả chính xác trong các tình huống cụ thể, hãy tham khảo ý kiến của các chuyên gia tài chính hoặc sử dụng các công cụ tính toán chuyên dụng.</p>
    
        <div className="calculator">
          <div className="display">{display || '0'}</div>
          <div className="buttons">
            <MyButton className="green" onClick={clearDisplay}>CE</MyButton>
            <MyButton className="green">MC</MyButton>
            <MyButton className="green">MR</MyButton>
            <MyButton className="green">M+</MyButton>
            <MyButton className="green">M-</MyButton>
            
            <MyButton onClick={() => handleClick('7')}>7</MyButton>
            <MyButton onClick={() => handleClick('8')}>8</MyButton>
            <MyButton onClick={() => handleClick('9')}>9</MyButton>
            <MyButton onClick={() => handleClick('/')}>÷</MyButton>
            <MyButton onClick={() => handleClick('√')}>√</MyButton>
            
            <MyButton onClick={() => handleClick('4')}>4</MyButton>
            <MyButton onClick={() => handleClick('5')}>5</MyButton>
            <MyButton onClick={() => handleClick('6')}>6</MyButton>
            <MyButton onClick={() => handleClick('*')}>×</MyButton>
            <MyButton onClick={() => handleClick('%')}>%</MyButton>
            
            <MyButton onClick={() => handleClick('1')}>1</MyButton>
            <MyButton onClick={() => handleClick('2')}>2</MyButton>
            <MyButton onClick={() => handleClick('3')}>3</MyButton>
            <MyButton onClick={() => handleClick('-')}>−</MyButton>
            <MyButton onClick={() => handleClick('1/x')}>1/x</MyButton>
            
            <MyButton onClick={() => handleClick('0')}>0</MyButton>
            <MyButton onClick={() => handleClick('.')}>.</MyButton>
            <MyButton onClick={() => handleClick('+/-')}>+/−</MyButton>
            <MyButton onClick={() => handleClick('+')}>+</MyButton>
            <MyButton className="equal" onClick={calculate}>=</MyButton>
          </div>
        </div>
  

    <h1>Hướng Dẫn Sử Dụng Máy Tính Trực Tuyến</h1>
        
        <h2>Chức Năng Phím</h2>
        <ul>
            <li>[0], [1], [2], … [9] – các phím số tiêu chuẩn;</li>
            <li>[00] – phím nhập 2 số không;</li>
            <li>[→] – xóa ký tự cuối cùng trên màn hình;</li>
            <li>[+/-] – thay đổi dấu hiệu toán học của số;</li>
            <li>[XY] – tính toán X với sức mạnh của Y;</li>
            <li>[√] – tính căn bậc hai;</li>
            <li>[+] – phép cộng, [-] – phép trừ, [х] – phép nhân, [÷] – phép chia;</li>
            <li>[%] – tính tỷ lệ phần trăm;</li>
            <li>[M +] – được lưu trong bộ nhớ với dấu [+];</li>
            <li>[M-] – được lưu trong bộ nhớ với dấu [-];</li>
            <li>[MR] – lấy nội dung của bộ nhớ;</li>
            <li>[MC] – xóa nội dung bộ nhớ;</li>
            <li>[AC] – đặt lại máy tính và đặt lại bộ nhớ;</li>
            <li>[C] – đặt lại máy tính mà không đặt lại bộ nhớ.</li>
        </ul>

        <h2>Ví Dụ Về Tính Toán Trên Máy Tính Trực Tuyến</h2>
        <ul>
            <li>Tính căn bậc hai của 529: 529 [√]. Kết quả bằng 23.</li>
            <li>Nâng số 3 lên lũy thừa 4: 3 [XY] 4 [=]. Kết quả bằng 81.</li>
            <li>Tính toán tỷ lệ phần trăm của số lượng: 500 [х] 25 [%]. Kết quả bằng 125.</li>
            <li>Tính phần trăm là một số của một số khác: 25 [÷] 500 [%]. Kết quả bằng 5 (%).</li>
            <li>Thêm phần trăm vào số: 500 [+] 25 [%]. Kết quả bằng 625.</li>
            <li>Tỷ lệ khấu trừ của số: 500 [-] 25 [%]. Kết quả bằng 375.</li>
        </ul>

        <h2>Nhập Lệnh Từ PC Bàn Phím</h2>
        <p>Để sử dụng máy tính trực tuyến, bạn có thể sử dụng cả hai nút số thông thường ở đầu bàn phím và nút số ở bên phải bàn phím.</p>
        <ul>
            <li>Để nhập [=] – phím [Enter].</li>
            <li>Để xóa ký tự cuối cùng – [Backspace] (phím mũi tên).</li>
            <li>Để nhập [+] – phím [+] ở trên cùng hoặc phím [+] trên bàn phím số ở bên phải.</li>
            <li>Để nhập [-] – phím [-] ở trên cùng hoặc phím [-] ở bên phải.</li>
            <li>Để nhập [x] (phép nhân) – phím [*] trên bàn phím số ở bên phải hoặc tổ hợp phím [*] và [Shift].</li>
            <li>Để nhập [÷] (chia) – phím [/] trên bàn phím số ở bên phải hoặc tổ hợp phím [:] và [Shift].</li>
        </ul>

        <p class="note">Lưu ý: Các lệnh này sẽ giúp bạn sử dụng máy tính trực tuyến một cách hiệu quả hơn.</p>
    </div>
  );
};

export default Calculator;
