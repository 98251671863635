import React, { useState } from 'react';
import '../../components/styles.css'; 

const RectangleCalculator = () => {
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [angleAlpha, setAngleAlpha] = useState('');
  const [angleBeta, setAngleBeta] = useState('');
  const [angleUnitAlpha, setAngleUnitAlpha] = useState('rad');
  const [angleUnitBeta, setAngleUnitBeta] = useState('rad');
  const [decimalPlaces, setDecimalPlaces] = useState(2);
  const [perimeter, setPerimeter] = useState(null);
  const [area, setArea] = useState(null);

  // Hàm chuyển đổi giữa radian và độ
  const convertAngle = (angle, unit) => {
    return unit === 'rad' ? angle : (angle * Math.PI) / 180; // Chuyển độ sang radian
  };

  // Hàm tính chu vi và diện tích hình chữ nhật
  const calculateRectangle = () => {
    const a = parseFloat(length);
    const b = parseFloat(width);
    const alpha = convertAngle(parseFloat(angleAlpha), angleUnitAlpha);
    const beta = convertAngle(parseFloat(angleBeta), angleUnitBeta);

    // Tính chu vi P = 2 * (a + b)
    const P = 2 * (a + b);
    setPerimeter(P.toFixed(decimalPlaces));

    // Tính diện tích S = a * b
    const S = a * b;
    setArea(S.toFixed(decimalPlaces));
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setLength('');
    setWidth('');
    setAngleAlpha('');
    setAngleBeta('');
    setPerimeter(null);
    setArea(null);
    setAngleUnitAlpha('rad');
    setAngleUnitBeta('rad');
  };

  return (
    <div class="content">
        <h1>Diện Tích và Chu Vi Hình Chữ Nhật</h1>
        
        <h2>Cách Tính Chu Vi</h2>
        <p>Cách tính chu vi và diện tích hình chữ nhật khá đơn giản và đây cũng là những kiến thức cơ bản giúp bất kỳ ai cũng cần nắm được để giải quyết nhiều bài toán từ đơn giản đến phức tạp.</p>
        <p>Chu vi hình chữ nhật bằng giá trị gấp hai lần tổng chiều dài cộng chiều rộng.</p>
        

        <h2>Cách Tính Diện Tích</h2>
        <p>Diện tích hình chữ nhật bằng tích của chiều dài nhân với chiều rộng.</p>
        
        <h2>Các Công Thức</h2>
        <img src="/image/DienTichChuVi/dien-tich-va-chu-vi-hinh-chu-nhat-300x240.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/DienTichChuVi/cong-thuc-dien-tich-va-chu-vi-hinh-chu-nhat.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />

        <h2>Định Nghĩa Hình Chữ Nhật</h2>
        <p>
            <ul>
                <li>Hình chữ nhật là một tứ giác có góc trong đều vuông.</li>
                <li>Các cạnh đối diện song song và bằng nhau.</li>
                <li>Đường chéo có độ dài bằng nhau, cắt nhau tại điểm giữa, nhưng không vuông góc với nhau.</li>
            </ul>
        </p>

        <h2>Các Thành Phần</h2>
        <p>
            <ul>
                <li><strong>P</strong> – chu vi</li>
                <li><strong>S</strong> – diện tích</li>
                <li><strong>a, b</strong> – chiều dài và chiều rộng</li>
            </ul>
        </p>

        <p class="note">Lưu ý: Để tính toán chính xác, bạn cần biết chiều dài và chiều rộng của hình chữ nhật.</p>



    <div className="container">
      <h2>Máy tính diện tích hình chữ nhật Online</h2>
      <p>Hãy đưa ra 2 giá trị</p>

      <div className="input-container">
        <span>a =</span>
        <input
          type="number"
          value={length}
          onChange={(e) => setLength(e.target.value)}
        />
      </div>

      <div className="input-container">
        <span>b =</span>
        <input
          type="number"
          value={width}
          onChange={(e) => setWidth(e.target.value)}
        />
      </div>

      <div className="input-container">
        <span>α =</span>
        <input
          type="number"
          value={angleAlpha}
          onChange={(e) => setAngleAlpha(e.target.value)}
        />
        <select
          value={angleUnitAlpha}
          onChange={(e) => setAngleUnitAlpha(e.target.value)}
        >
          <option value="rad">rad</option>
          <option value="deg">°</option>
        </select>
      </div>

      <div className="input-container">
        <span>β =</span>
        <input
          type="number"
          value={angleBeta}
          onChange={(e) => setAngleBeta(e.target.value)}
        />
        <select
          value={angleUnitBeta}
          onChange={(e) => setAngleUnitBeta(e.target.value)}
        >
          <option value="rad">rad</option>
          <option value="deg">°</option>
        </select>
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateRectangle}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {perimeter && (
        <div className="result">
          <p>Chu vi P = {perimeter}</p>
          <p>Diện tích S = {area}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default RectangleCalculator;
