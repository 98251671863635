import React, { useState } from 'react';
import '../../components/styles.css'; 

const DecimalLogarithmCalculator = () => {
  const [value, setValue] = useState('');
  const [logResult, setLogResult] = useState(null);
  const [roundedDecimal, setRoundedDecimal] = useState(2);

  // Function to calculate logarithm base 10
  const calculateLogBase10 = () => {
    if (value > 0) {
      const result = Math.log10(value); // Logarithm base 10
      setLogResult(result.toFixed(roundedDecimal));
    } else {
      setLogResult('Giá trị không hợp lệ');
    }
  };

  return (
    <div className="container">
      <h2>Máy Tính Giải Logarit Thập Phân Online</h2>

      <h3>Hãy đưa ra giá trị x</h3>
      <div className="input-container">
        <label>x =</label>
        <input
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Nhập giá trị x"
        />
      </div>

      <div className="rounded-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={roundedDecimal}
          onChange={(e) => setRoundedDecimal(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateLogBase10}>Tính log₁₀ x</button>

      {logResult !== null && (
        <div className="result">
          <p>log₁₀ x = {logResult}</p>
        </div>
      )}
    </div>
  );
};

export default DecimalLogarithmCalculator;
