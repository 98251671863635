import React, { useState } from 'react';
import '../../components/styles.css';

const AngleConverter = () => {
  const [angleValue, setAngleValue] = useState('');
  const [fromUnit, setFromUnit] = useState('°');
  const [toUnit, setToUnit] = useState('°');
  const [result, setResult] = useState(null);
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');

  const convertAngle = () => {
    const value = parseFloat(angleValue);
    let convertedValue;

    // Chuyển đổi giữa các đơn vị
    if (fromUnit === '°') {
      if (toUnit === 'rad') {
        convertedValue = (value * Math.PI) / 180; // Độ sang radian
      } else if (toUnit === 'g') {
        convertedValue = (value * 200) / 180; // Độ sang grad (gon)
      } else if (toUnit === '°') {
        convertedValue = value; // Độ sang độ
      }
    } else if (fromUnit === 'rad') {
      if (toUnit === '°') {
        convertedValue = (value * 180) / Math.PI; // Radian sang độ
      } else if (toUnit === 'g') {
        convertedValue = (value * 200) / Math.PI; // Radian sang grad (gon)
      } else if (toUnit === 'rad') {
        convertedValue = value; // Radian sang radian
      }
    } else if (fromUnit === 'g') {
      if (toUnit === '°') {
        convertedValue = (value * 180) / 200; // Grad sang độ
      } else if (toUnit === 'rad') {
        convertedValue = (value * Math.PI) / 200; // Grad sang radian
      } else if (toUnit === 'g') {
        convertedValue = value; // Grad sang grad
      }
    }

    if (convertedValue !== undefined) {
      setResult(convertedValue);
    }
  };

  const clearFields = () => {
    setAngleValue('');
    setFromUnit('°');
    setToUnit('°');
    setResult(null);
    setMinutes('');
    setSeconds('');
  };

  return (
    <div className="container">
      <h2>Máy tính chuyển đổi góc</h2>
      <p>Hãy đưa ra giá trị góc và chọn đơn vị</p>
      <input
        type="number"
        value={angleValue}
        onChange={(e) => setAngleValue(e.target.value)}
        placeholder="Nhập giá trị"
      />
      <select value={fromUnit} onChange={(e) => setFromUnit(e.target.value)}>
        <option value="°">độ (°)</option>
        <option value="rad">radian (rad)</option>
        <option value="g">grad (gon)</option>
      </select>
      <span>đến</span>
      <select value={toUnit} onChange={(e) => setToUnit(e.target.value)}>
        <option value="°">độ (°)</option>
        <option value="rad">radian (rad)</option>
        <option value="g">grad (gon)</option>
      </select>

      <button onClick={convertAngle}>Chuyển đổi</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Kết quả: {result.toFixed(6)} {toUnit}</p>
        </div>
      )}
    </div>
  );
};

export default AngleConverter;
