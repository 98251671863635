import React, { useState } from 'react';
import '../../components/styles.css';  

const ElectricityCalculator = () => {
  const [power, setPower] = useState('');
  const [pricePerKwh, setPricePerKwh] = useState(1750); // Default price per kWh (₫)
  const [hoursPerDay, setHoursPerDay] = useState(0);
  const [minutesPerDay, setMinutesPerDay] = useState(0);
  const [consumption, setConsumption] = useState(null);
  const [cost, setCost] = useState(null);

  // Function to calculate electricity consumption and cost
  const calculateConsumptionAndCost = () => {
    const powerInKw = power / 1000; // Convert power from watts to kilowatts
    const totalTimeInHours = parseFloat(hoursPerDay) + parseFloat(minutesPerDay) / 60; // Convert time to hours
    const dailyConsumption = powerInKw * totalTimeInHours; // kWh per day

    // Consumption calculations
    const consumptionResults = {
      hourly: powerInKw,
      daily: dailyConsumption,
      monthly: dailyConsumption * 30,
      yearly: dailyConsumption * 365
    };

    // Cost calculations based on consumption
    const costResults = {
      hourly: consumptionResults.hourly * pricePerKwh,
      daily: consumptionResults.daily * pricePerKwh,
      monthly: consumptionResults.monthly * pricePerKwh,
      yearly: consumptionResults.yearly * pricePerKwh
    };

    setConsumption(consumptionResults);
    setCost(costResults);
  };

  return (
    <div className="container">
      <h2>Máy Tính Tiêu Thụ Điện Năng và Giá Trị</h2>

      <h3>Nhập công suất hoặc mức tiêu thụ điện</h3>
      <div className="input-container">
        <label>Công suất (W):</label>
        <input
          type="number"
          value={power}
          onChange={(e) => setPower(e.target.value)}
          placeholder="Nhập công suất (W)"
        />
      </div>

      <h3>Giá điện (₫/kWh)</h3>
      <div className="input-container">
        <label>Giá cho 1 kWh điện:</label>
        <input
          type="number"
          value={pricePerKwh}
          onChange={(e) => setPricePerKwh(e.target.value)}
          placeholder="Nhập giá cho mỗi kWh"
        />
        <p>Giá mặc định trung bình: 1.750 ₫/kWh</p>
      </div>

      <h3>Thời gian sử dụng thiết bị mỗi ngày</h3>
      <div className="time-inputs">
        <label>Giờ:</label>
        <input
          type="number"
          value={hoursPerDay}
          onChange={(e) => setHoursPerDay(e.target.value)}
        />
        <label>Phút:</label>
        <input
          type="number"
          value={minutesPerDay}
          onChange={(e) => setMinutesPerDay(e.target.value)}
        />
      </div>

      <button onClick={calculateConsumptionAndCost}>Tính tiêu thụ điện</button>

      {consumption && cost && (
        <div className="results">
          <h4>Thời gian sử dụng và tiêu thụ điện:</h4>
          <table>
            <thead>
              <tr>
                <th>Thời gian</th>
                <th>Tiêu thụ điện (kWh)</th>
                <th>Giá (₫)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1 Giờ</td>
                <td>{consumption.hourly.toFixed(2)}</td>
                <td>{cost.hourly.toFixed(2)} ₫</td>
              </tr>
              <tr>
                <td>1 Ngày</td>
                <td>{consumption.daily.toFixed(2)}</td>
                <td>{cost.daily.toFixed(2)} ₫</td>
              </tr>
              <tr>
                <td>1 Tháng</td>
                <td>{consumption.monthly.toFixed(2)}</td>
                <td>{cost.monthly.toFixed(2)} ₫</td>
              </tr>
              <tr>
                <td>1 Năm</td>
                <td>{consumption.yearly.toFixed(2)}</td>
                <td>{cost.yearly.toFixed(2)} ₫</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ElectricityCalculator;
