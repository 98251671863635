import React, { useState } from 'react';
import '../../components/styles.css'; 

const SimpleMean = () => {
  const [values, setValues] = useState(Array(10).fill(''));
  const [rounded, setRounded] = useState(2); 
  const [mean, setMean] = useState(null);
  

  const calculateMean = () => {
    let total = 0;
    let count = 0;

    for (let i = 0; i < values.length; i++) {
      const value = parseFloat(values[i]);
      if (!isNaN(value)) {
        total += value;
        count++;
      }
    }

    if (count > 0) {
      const result = total / count;
      setMean(result.toFixed(rounded));
    } else {
      setMean('Vui lòng nhập ít nhất một giá trị hợp lệ');
    }
  };
  const clearFields = () => {
    setValues('');
    setRounded('');
    setMean(null);
  };

  const handleValueChange = (index, newValue) => {
    const updatedValues = [...values];
    updatedValues[index] = newValue;
    setValues(updatedValues);
  };

  return (
    <div className='content'>
      <h1>Trung bình cộng đơn giản</h1>
      <p>Bình cộng đơn giản là kết quả của tổng tất cả các giá trị chia cho số các số hạng. Trung bình cộng đơn giản của một dãy số là thương số giữa tổng giá trị của tập hợp số đó và số phần tử trong tập hợp đó.</p>
      <h2>Công thức</h2>
      <img src="/image/TrungBinh/trung-binh-cong-don-gian.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />


    <div className="container">
      <h2>Máy Tính Trung Bình Cộng Đơn Giản Online</h2>

      <h3>Hãy đưa ra các giá trị để tính trung bình</h3>

      {values.map((value, index) => (
        <div className="input-container" key={index}>
          <span>x{index + 1}:</span>
          <input
            type="number"
            value={value}
            onChange={(e) => handleValueChange(index, e.target.value)}
            placeholder={`Giá trị x${index + 1}`}
          />
        </div>
      ))}

      <div className="rounded-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={rounded}
          onChange={(e) => setRounded(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateMean}>Tính trung bình cộng</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {mean !== null && (
        <div className="result">
          <p>Trung bình cộng: {mean}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default SimpleMean;
