import React, { useState } from 'react';
import '../../components/styles.css';

const LengthConverter = () => {
  const [lengthValue, setLengthValue] = useState('');
  const [fromUnit, setFromUnit] = useState('cm');
  const [toUnit, setToUnit] = useState('m');
  const [result, setResult] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const convertLength = () => {
    const value = parseFloat(lengthValue);
    let convertedValue;

    // Chuyển đổi giữa các đơn vị
    const conversionRates = {
      'cm': 1 / 100,
      'm': 1,
      'km': 1000,
      'in': 0.0254,
      'ft': 0.3048,
      'yd': 0.9144,
      'mi': 1609.34,
      'lea': 4828.03,
      'pm': 1e-12,
      'nm': 1e-9,
      'μm': 1e-6,
      'mm': 1e-3,
      'dm': 0.1
    };

    if (fromUnit && toUnit) {
      convertedValue = (value * conversionRates[fromUnit]) / conversionRates[toUnit];
      setResult(convertedValue.toFixed(decimalPlaces));
    }
  };

  const clearFields = () => {
    setLengthValue('');
    setFromUnit('cm');
    setToUnit('m');
    setResult(null);
    setDecimalPlaces(2);
  };

  return (
    <div className="container">
      <h2>Máy tính chuyển đổi chiều dài</h2>
      <input
        type="number"
        value={lengthValue}
        onChange={(e) => setLengthValue(e.target.value)}
        placeholder="Nhập giá trị"
      />
      <select value={fromUnit} onChange={(e) => setFromUnit(e.target.value)}>
        <option value="pm">picômét</option>
        <option value="nm">nanômét</option>
        <option value="μm">micrômét</option>
        <option value="mm">milimét</option>
        <option value="cm">xentimét</option>
        <option value="dm">đêximét</option>
        <option value="m">mét</option>
        <option value="km">kilômét</option>
        <option value="in">inch</option>
        <option value="ft">foot</option>
        <option value="yd">yard</option>
        <option value="mi">dặm</option>
        <option value="lea">league</option>
      </select>
      <span>đến</span>
      <select value={toUnit} onChange={(e) => setToUnit(e.target.value)}>
        <option value="pm">picômét</option>
        <option value="nm">nanômét</option>
        <option value="μm">micrômét</option>
        <option value="mm">milimét</option>
        <option value="cm">xentimét</option>
        <option value="dm">đêximét</option>
        <option value="m">mét</option>
        <option value="km">kilômét</option>
        <option value="in">inch</option>
        <option value="ft">foot</option>
        <option value="yd">yard</option>
        <option value="mi">dặm</option>
        <option value="lea">league</option>
      </select>

      <button onClick={convertLength}>Chuyển đổi</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          <p>Kết quả: {result}</p>
        </div>
      )}

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
          min="0"
        />
        <span>số thập phân</span>
      </div>
    </div>
  );
};

export default LengthConverter;
