import React, { useState } from 'react';
import '../../components/styles.css'; 

const TriangleCalculator = () => {
  const [sideA, setSideA] = useState('');
  const [sideB, setSideB] = useState('');
  const [sideC, setSideC] = useState('');
  const [angleAlpha, setAngleAlpha] = useState('');
  const [angleBeta, setAngleBeta] = useState('');
  const [angleGamma, setAngleGamma] = useState('');
  const [angleUnitAlpha, setAngleUnitAlpha] = useState('rad');
  const [angleUnitBeta, setAngleUnitBeta] = useState('rad');
  const [angleUnitGamma, setAngleUnitGamma] = useState('rad');
  const [decimalPlaces, setDecimalPlaces] = useState(2);
  const [perimeter, setPerimeter] = useState(null);
  const [area, setArea] = useState(null);

  // Hàm chuyển đổi giữa radian và độ
  const convertAngle = (angle, unit) => {
    return unit === 'rad' ? angle : (angle * Math.PI) / 180; // Chuyển độ sang radian
  };

  // Hàm tính chu vi và diện tích tam giác
  const calculateTriangle = () => {
    const a = parseFloat(sideA);
    const b = parseFloat(sideB);
    const c = parseFloat(sideC);
    const alpha = convertAngle(parseFloat(angleAlpha), angleUnitAlpha);
    const beta = convertAngle(parseFloat(angleBeta), angleUnitBeta);
    const gamma = convertAngle(parseFloat(angleGamma), angleUnitGamma);

    // Tính chu vi P = a + b + c
    const P = a + b + c;
    setPerimeter(P.toFixed(decimalPlaces));

    // Tính diện tích S sử dụng công thức Heron nếu không có chiều cao
    const s = P / 2;
    const S = Math.sqrt(s * (s - a) * (s - b) * (s - c));
    setArea(S.toFixed(decimalPlaces));
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setSideA('');
    setSideB('');
    setSideC('');
    setAngleAlpha('');
    setAngleBeta('');
    setAngleGamma('');
    setPerimeter(null);
    setArea(null);
    setAngleUnitAlpha('rad');
    setAngleUnitBeta('rad');
    setAngleUnitGamma('rad');
  };

  return (
    <div class="content">
        <h1>Diện Tích và Chu Vi Tam Giác</h1>
        
        <h2>Chu Vi Tam Giác</h2>
        <p>Chu vi <em>P</em> của tam giác bằng tổng 3 cạnh của tam giác đó.</p>

        <h2>Diện Tích Tam Giác</h2>
        <p>Diện tích <em>S</em> của tam giác sẽ bằng 1/2 tích của chiều cao và chiều dài cạnh đáy của tam giác.</p>
        
        <h2>Các Công Thức</h2>
        <img src="/image/DienTichChuVi/dien-tich-va-chu-vi-cua-tam-giac-300x240.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/DienTichChuVi/cong-thuc-dien-tich-va-chu-vi-cua-tam-giac-254x300.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />


        <h2>Định Nghĩa Tam Giác</h2>
        <p>
            <ul>
                <li>Tổng các góc của một tam giác là 180°.</li>
                <li>Đường cao là đường vuông góc từ một điểm tới cạnh đối diện.</li>
                <li>Tâm đường tròn ngoại tiếp là giao điểm đường trung trực của các cạnh.</li>
                <li>Đường trung trực của cạnh là đường vuông góc với cạnh tại trung điểm của cạnh đó.</li>
                <li>Tâm của đường tròn nội tiếp là giao điểm của các đường phân giác.</li>
                <li>Đường phân giác chia góc thành hai nửa bằng nhau.</li>
                <li>Trung tuyến là đường nối đỉnh tam giác với điểm giữa của cạnh đối diện.</li>
                <li>Trung tuyến cắt nhau tại điểm chia chiều dài của chúng theo tỷ lệ 2:1.</li>
            </ul>
        </p>

        <h2>Các Thành Phần</h2>
        <p>
            <ul>
                <li><strong>P</strong> – chu vi</li>
                <li><strong>S</strong> – diện tích</li>
                <li><strong>a, b, c</strong> – các cạnh</li>
                <li><strong>ha</strong> – chiều cao trên cạnh <em>a</em></li>
            </ul>
        </p>

        <p class="note">Lưu ý: Để tính toán chính xác, bạn cần biết các cạnh và chiều cao của tam giác.</p>


    <div className="container">
      <h2>Máy tính diện tích tam giác Online</h2>
      <p>Hãy đưa ra 3 giá trị cạnh và góc tam giác</p>

      <div className="input-container">
        <span>a =</span>
        <input
          type="number"
          value={sideA}
          onChange={(e) => setSideA(e.target.value)}
        />
      </div>

      <div className="input-container">
        <span>b =</span>
        <input
          type="number"
          value={sideB}
          onChange={(e) => setSideB(e.target.value)}
        />
      </div>

      <div className="input-container">
        <span>c =</span>
        <input
          type="number"
          value={sideC}
          onChange={(e) => setSideC(e.target.value)}
        />
      </div>

      <div className="input-container">
        <span>α =</span>
        <input
          type="number"
          value={angleAlpha}
          onChange={(e) => setAngleAlpha(e.target.value)}
        />
        <select
          value={angleUnitAlpha}
          onChange={(e) => setAngleUnitAlpha(e.target.value)}
        >
          <option value="rad">rad</option>
          <option value="deg">°</option>
        </select>
      </div>

      <div className="input-container">
        <span>β =</span>
        <input
          type="number"
          value={angleBeta}
          onChange={(e) => setAngleBeta(e.target.value)}
        />
        <select
          value={angleUnitBeta}
          onChange={(e) => setAngleUnitBeta(e.target.value)}
        >
          <option value="rad">rad</option>
          <option value="deg">°</option>
        </select>
      </div>

      <div className="input-container">
        <span>γ =</span>
        <input
          type="number"
          value={angleGamma}
          onChange={(e) => setAngleGamma(e.target.value)}
        />
        <select
          value={angleUnitGamma}
          onChange={(e) => setAngleUnitGamma(e.target.value)}
        >
          <option value="rad">rad</option>
          <option value="deg">°</option>
        </select>
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateTriangle}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {perimeter && (
        <div className="result">
          <p>Chu vi P = {perimeter}</p>
          <p>Diện tích S = {area}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default TriangleCalculator;
