import React, { useState } from 'react';
import '../../components/styles.css'; 

const PythagoreanTheorem = () => {
  const [a, setA] = useState('');
  const [b, setB] = useState('');
  const [c, setC] = useState('');
  const [decimalPlaces, setDecimalPlaces] = useState(2);
  const [result, setResult] = useState(null);

  // Hàm tính cạnh huyền hoặc cạnh còn lại
  const calculatePythagorean = () => {
    const sideA = parseFloat(a);
    const sideB = parseFloat(b);
    const hypotenuse = parseFloat(c);

    let resultValue;

    if (hypotenuse) {
      // Tính cạnh huyền c
      resultValue = Math.sqrt(sideA * sideA + sideB * sideB);
    } else {
      // Tính cạnh còn lại
      resultValue = Math.sqrt(hypotenuse * hypotenuse - sideB * sideB);
    }

    setResult(resultValue.toFixed(decimalPlaces));
  };

  // Xóa dữ liệu
  const clearFields = () => {
    setA('');
    setB('');
    setC('');
    setResult(null);
  };

  return (
    <div class="content">
        <h1>Định Lý Pythagore</h1>
        
        <h2>Định Nghĩa</h2>
        <p>Trong toán học, định lý Pythagore (còn gọi là định lý Pytago) là một liên hệ căn bản giữa ba cạnh của một tam giác vuông. Định lý phát biểu rằng bình phương cạnh huyền (cạnh đối diện với góc vuông) bằng tổng bình phương của hai cạnh còn lại.</p>
        <p>Định lý có thể viết thành một phương trình liên hệ độ dài của các cạnh là <strong>a</strong>, <strong>b</strong> và <strong>c</strong>, thường gọi là “công thức Pythagore”.</p>
        
        <h2>Các Công Thức</h2>
        <img src="/image/DienTichChuVi/dinh-ly-pitago-300x240.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />
        <img src="/image/DienTichChuVi/cong-thuc-tinh-pitago-205x300.jpg" alt="Công thức " style={{ maxWidth: '50%', height: 'auto' }} />

        <h2>Các Thành Phần</h2>
        <p>
            <ul>
                <li><strong>c</strong> – cạnh huyền</li>
                <li><strong>a</strong>, <strong>b</strong> – các cạnh bên tạo thành góc vuông</li>
            </ul>
        </p>

        <p class="note">Lưu ý: Định lý Pythagore chỉ áp dụng cho tam giác vuông.</p>

    <div className="container">
      <h2>Máy tính định lý Pythagore Online</h2>
      <p>Hãy đưa ra 2 giá trị</p>

      <div className="input-container">
        <span>a =</span>
        <input
          type="number"
          value={a}
          onChange={(e) => setA(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>b =</span>
        <input
          type="number"
          value={b}
          onChange={(e) => setB(e.target.value)}
        />
      </div>
      <div className="input-container">
        <span>c =</span>
        <input
          type="number"
          value={c}
          onChange={(e) => setC(e.target.value)}
        />
      </div>

      <div className="decimal-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={decimalPlaces}
          onChange={(e) => setDecimalPlaces(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculatePythagorean}>Tính</button>
      <button className="clear-button" onClick={clearFields}>Xóa</button>

      {result !== null && (
        <div className="result">
          {c ? (
            <p>Diện tích c = {result}</p>
          ) : (
            <p>Cạnh huyền = {result}</p>
          )}
        </div>
      )}
    </div>
    <p>Nói cách khác, trong một tam giác vuông với các cạnh góc vuông có chiều dài là a và b và độ dài cạnh huyền là c, thì ta luôn có a2 + b2 = c2. Định lý Pytago là một trong những trụ cột chính của hình học cơ bản. Có vô vàn ứng dụng thực tiễn như tìm khoảng cách giữa hai điểm trên một mặt phẳng tọa độ.</p>
    </div>
  );
};

export default PythagoreanTheorem;
