import React from 'react';
import '../Pages/styles/styles.css';


const Geometry = () => {
  const geometryItems = [
    {
      title: 'Định lý Pythagore',
      image: '/image/DienTichChuVi/dinh-ly-pytago.jpg', 
      link: '/geometry/pythagoreanTheorem'
    },
    {
      title: 'Đa giác đều',
      image: '/image/DienTichChuVi/da-giac-deu.jpg',
      link: '/geometry/polygon'
    },
    {
      title: 'Lục giác đều',
      image: '/image/DienTichChuVi/hinh-luc-giac-deu.jpg',
      link: '/geometry/hexagon'
    },
    {
      title: 'Ngũ giác đều',
      image: '/image/DienTichChuVi/hinh-ngu-giac-deu.jpg',
      link: '/geometry/pentagon'
    },
    {
      title: 'Diện tích hình thang',
      image: '/image/DienTichChuVi/hinh-thang.jpg',
      link: '/geometry/trapezoid'
    },
    {
      title: 'Hình bình hành',
      image: '/image/DienTichChuVi/hinh-binh-hanh.jpg',
      link: '/geometry/parallelogram'
    },
    {
      title: 'Hình thoi',
      image: '/image/DienTichChuVi/hinh-thoi.jpg',
      link: '/geometry/rhombus'
    },
    {
      title: 'Hình chữ nhật',
      image: '/image/DienTichChuVi/hinh-chu-nhat.jpg',
      link: '/geometry/rectangle'
    },
    {
      title: 'Hình vuông',
      image: '/image/DienTichChuVi/hinh-vuong.jpg',
      link: '/geometry/square'
    },
    {
      title: 'Tam giác',
      image: '/image/DienTichChuVi/dien-tich-va-chu-vi-cua-tam-giac-300x240.jpg',
      link: '/geometry/triangle'
    },
    {
      title: 'Hình tròn',
      image: '/image/DienTichChuVi/dien-tich-va-chu-vi-hinh-tron.jpg',
      link: '/geometry/circle'
    },
    {
      title: 'Tam giác vuông',
      image: '/image/DienTichChuVi/dien-tich-va-chu-vi-cua-tam-giac-vuong-300x240.jpg',
      link: '/geometry/righttriangle'
    }
  ];

  return (
    <div className='content'>
        <h1>Diện tích và chu vi</h1>
        <div className="geometry-container">
            {geometryItems.map((item, index) => (
                <div key={index} className="geometry-item">
                <h2>{item.title}</h2>
                <img src={item.image} alt={item.title} />
                <a href={item.link}>
                    <i className="arrow-icon">➡️</i>
                </a>
                </div>
            ))}
        </div>
    </div>
  );
};

export default Geometry;
