import React, { useState } from 'react';
import '../../components/styles.css'; 

const SinCosCalculator = () => {
  const [angle, setAngle] = useState('');
  const [sinValue, setSinValue] = useState(null);
  const [roundedSin, setRoundedSin] = useState(6);
  const [alpha1Value, setAlpha1Value] = useState('');
  const [alpha2Value, setAlpha2Value] = useState('');
  const [angleUnit, setAngleUnit] = useState('deg'); // 'deg' cho độ, 'rad' cho radian

  // Hàm tính giá trị sin
  const calculateSin = () => {
    let radians = angleUnit === 'deg' ? (angle * Math.PI) / 180 : angle;
    const sinResult = Math.sin(radians);
    setSinValue(sinResult.toFixed(roundedSin));
  };

  // Hàm tính giá trị ngược lại (sin^-1)
  const calculateInverseSin = () => {
    const value = parseFloat(alpha1Value);
    if (value < -1 || value > 1) {
      setAlpha2Value('Giá trị không hợp lệ');
    } else {
      const angleInRad = Math.asin(value); // Tính arcsin
      const angleInDeg = (angleInRad * 180) / Math.PI; // Chuyển đổi sang độ
      const kValue1 = ` + k.360°`; // Để tính thêm các nghiệm
      const kValue2 = ` + k.360°`;
      setAlpha2Value(`${angleInDeg.toFixed(4)}°${kValue1} và ${(180 - angleInDeg).toFixed(4)}°${kValue2}`);
    }
  };

  return (
    <div className="container">
      <h2>Máy Tính Giải Sin Online</h2>

      <h3>Hãy viết góc α</h3>
      <div className="input-container">
        <span>α =</span>
        <input
          type="number"
          value={angle}
          onChange={(e) => setAngle(e.target.value)}
        />
        <select value={angleUnit} onChange={(e) => setAngleUnit(e.target.value)}>
          <option value="deg">°</option>
          <option value="rad">rad</option>
        </select>
      </div>
      
      <div className="rounded-container">
        <label>Làm tròn</label>
        <input
          type="number"
          value={roundedSin}
          onChange={(e) => setRoundedSin(e.target.value)}
        />
        <span>số thập phân</span>
      </div>

      <button onClick={calculateSin}>Tính sin α</button>
      
      {sinValue !== null && (
        <div className="result">
          <p>sin α = {sinValue}</p>
        </div>
      )}

      <h3>Hãy cho giá trị sin α và chọn đơn vị góc</h3>
      <div className="input-container">
        <span>sin α =</span>
        <input
          type="number"
          value={alpha1Value}
          onChange={(e) => setAlpha1Value(e.target.value)}
        />
      </div>
      <button onClick={calculateInverseSin}>Tính α từ sin α</button>
      
      {alpha2Value && (
        <div className="result">
          <p>α1 = {alpha2Value}</p>
        </div>
      )}
    </div>
  );
};

export default SinCosCalculator;
