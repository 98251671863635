import { NavLink } from 'react-router-dom';
import './Header.css';


const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <h1><NavLink to="/home" activeClassName="active-link">WEB TÍNH TOÁN MIỄN PHÍ</NavLink></h1> 
      </div>
      <nav className="nav">
        <ul>
          <li class="lia"><a href="/home">Home</a></li>
          <li class="lia"><a href="/menu">Menu</a></li>
          <li class="lia"><a href="/about">About</a></li>
          <li class="lia"><a href="/contact">Contact</a></li>
        </ul>
      </nav>
      <div className="search-bar">
        <input type="text" placeholder="Tìm kiếm ..." />
        <buttonheader type="submit">Tìm</buttonheader>
      </div>
    </header>
  );
};

export default Header;
