import React, { useState } from 'react';
import '../../components/styles.css'; 

const FuelConsumptionCalculator = () => {
  const [distance, setDistance] = useState('');
  const [fuelConsumption, setFuelConsumption] = useState('');
  const [fuelPrice, setFuelPrice] = useState('');
  const [includeReturnTrip, setIncludeReturnTrip] = useState(false);
  const [totalConsumption, setTotalConsumption] = useState(null);
  const [totalCost, setTotalCost] = useState(null);

  // Hàm tính toán tiêu thụ nhiên liệu và chi phí
  const calculateFuelConsumptionAndCost = () => {
    const totalDistance = includeReturnTrip ? distance * 2 : distance;
    const consumption = (totalDistance * fuelConsumption) / 100; // Tổng nhiên liệu tiêu thụ (lít)
    const cost = consumption * fuelPrice; // Tổng chi phí nhiên liệu (₫)
    setTotalConsumption(consumption);
    setTotalCost(cost);
  };

  return (
    <div className="container">
      <h2>Máy Tính Tiêu Thụ Nhiên Liệu và Giá Thành</h2>

      <div className="input-container">
        <label>Nhập khoảng cách (km):</label>
        <input
          type="number"
          value={distance}
          onChange={(e) => setDistance(e.target.value)}
          placeholder="Nhập khoảng cách"
        />
      </div>

      <div className="input-container">
        <label>Nhập mức tiêu thụ nhiên liệu trung bình (lít/100 km):</label>
        <input
          type="number"
          value={fuelConsumption}
          onChange={(e) => setFuelConsumption(e.target.value)}
          placeholder="Lít/100 km"
        />
      </div>

      <div className="input-container">
        <label>Nhập giá xăng/dầu (₫/lít):</label>
        <input
          type="number"
          value={fuelPrice}
          onChange={(e) => setFuelPrice(e.target.value)}
          placeholder="₫/lít"
        />
      </div>

      <div className="input-container">
        <label>Tính thêm đường quay về:</label>
        <input
          type="checkbox"
          checked={includeReturnTrip}
          onChange={(e) => setIncludeReturnTrip(e.target.checked)}
        />
      </div>

      <button onClick={calculateFuelConsumptionAndCost}>Tính tiêu thụ nhiên liệu</button>

      {totalConsumption !== null && totalCost !== null && (
        <div className="results">
          <h4>Kết quả:</h4>
          <table>
            <thead>
              <tr>
                <th>Khoảng cách</th>
                <th>Tiêu thụ nhiên liệu (lít)</th>
                <th>Giá (₫)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{distance} km</td>
                <td>{totalConsumption.toFixed(2)} lít</td>
                <td>{totalCost.toFixed(2)} ₫</td>
              </tr>
              <tr>
                <td>1 km</td>
                <td>{(totalConsumption / distance).toFixed(4)} lít</td>
                <td>{(totalCost / distance).toFixed(2)} ₫</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default FuelConsumptionCalculator;
