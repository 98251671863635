import React from 'react';
import '../Pages/styles/styles.css';

const Average = () => {
  const averageItems = [
    {
      title: 'Trung bình cộng có trọng số',
      image: 'path-to-image-weighted-average.jpg',
      link: '/average/weighted-mean'
    },
    {
      title: 'Trung bình cộng đơn giản',
      image: 'path-to-image-simple-average.jpg',
      link: '/average/simple-mean'
    }
  ];

  return (
    <div className='content'>
        <h1>Các loại trung bình</h1>
        <div className="geometry-container"> {/* Sử dụng chung CSS với geometry.js */}
            {averageItems.map((item, index) => (
                <div key={index} className="geometry-item"> {/* Sử dụng chung CSS */}
                <h2>{item.title}</h2>
                <img src={item.image} alt={item.title} />
                <a href={item.link}>
                    <i className="arrow-icon">➡️</i>
                </a>
                </div>
            ))}
        </div>
    </div>
  );
};

export default Average;
