import React from 'react';
import '../Pages/styles/styles.css';

const Equations = () => {
  const equationItems = [
    
    {
      title: 'Hệ phương trình',
      image: 'path-to-image-system-equations.jpg',
      link: '/equations/system'
    },
    {
      title: 'Phương trình bậc nhất',
      image: 'path-to-image-linear-equation.jpg',
      link: '/equations/linear'
    },
    {
      title: 'Phương trình bậc hai',
      image: 'path-to-image-quadratic-equation.jpg',
      link: '/equations/quadratic'
    }
  ];

  return (
    <div className='content'>
        <h1>Các loại phương trình</h1>
        <div className="geometry-container"> {/* Sử dụng cùng cấu trúc với geometry.js */}
            {equationItems.map((item, index) => (
                <div key={index} className="geometry-item"> {/* Sử dụng chung CSS */}
                <h2>{item.title}</h2>
                <img src={item.image} alt={item.title} />
                <a href={item.link}>
                    <i className="arrow-icon">➡️</i>
                </a>
                </div>
            ))}
        </div>
    </div>
  );
};

export default Equations;
